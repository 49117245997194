import { useCallback, useContext } from 'react';

import { GlobalDataContext } from '@/components/Global/GlobalDataManagement/GlobalDataManagement';

import { SENTRY_ACTIONS, sentryTracking } from '../sentryActions';

export const useSentryActions = () => {
    const globalData = useContext(GlobalDataContext);
    const userId = globalData?.state?.loggedInUser?.credentialId;

    const handleSentryAction = useCallback(
        (error: unknown, sentryAction?: SENTRY_ACTIONS) => {
            sentryTracking(error, sentryAction, userId);
        },
        [userId]
    );
    return { handleSentryAction };
};
