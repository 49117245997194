import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import OneHuman from '@/resources/image/one_human_mountain.svg';

import { Info, Title } from './styles';

const ErrorContent = () => {
    const { t } = useTranslation();

    return (
        <>
            <img src={OneHuman} alt="people" width={280} />
            <Title>{t('administratorPanel_subscription_somethingWentWrong')}</Title>
            <Trans>
                <ErrorInfo>{t('administratorPanel_tab_subscription_makeSure_message')}</ErrorInfo>
            </Trans>
        </>
    );
};

const ErrorInfo = styled(Info)`
    width: 329px;
    text-align: left;
    line-height: 25px;
`;

export default ErrorContent;
