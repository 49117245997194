/* eslint-disable @typescript-eslint/no-explicit-any */
export const formatUnicorn = (text: string, args: { [key: string]: any }) =>
    Object.entries(args).reduce((prev: string, [key, value]: [string, any]) => {
        return prev.replace(new RegExp(`\\{${key}\\}`, 'gi'), value);
    }, text);

// example:
// formatUnicorn('Hello {name}', { name: 'Adam' })
// result: Hello Adam

export const isString = el => typeof el === 'string';
