import { Typography } from '@mui/material';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import AuthFooter from '@/components/Global/layouts/AuthLayout/AuthFooter';
import FreeQuestLogo from '@/components/Global/layouts/AuthLayout/FreeQuestLogo';
import { COLOR, GLOBAL_MEDIA_QUERIES } from '@/globalStyles';
import { ReactComponent as LeavesSVG } from '@/resources/image/leaves-top.svg';
import { ReactComponent as HeroSVG } from '@/resources/image/two_human_happy.svg';

import { IAuthLayoutProps } from './AuthLayout';

type IAuthLayoutDesktopProps = IAuthLayoutProps;

const AuthLayoutDesktop = ({ className, children }: IAuthLayoutDesktopProps) => {
    const { t } = useTranslation();

    return (
        <div className={className}>
            <LeftPanelWrapper>
                <FreeQuestLogo />
                <LeftPanelTitle variant="mid_title">{t('auth_common_leave_management')}!</LeftPanelTitle>
                <Hero>
                    <HeroSVG />
                </Hero>
                <AuthFooter addEnjoyText />
                <LeavesBackground>
                    <LeavesSVG />
                </LeavesBackground>
            </LeftPanelWrapper>
            <RightPanelWrapper>
                <AuthSectionContent>{children}</AuthSectionContent>
                <LoveText variant="table_text">{t('moreView_footer_createdBy')}</LoveText>
            </RightPanelWrapper>
        </div>
    );
};

const Hero = styled.div`
    & svg {
        transform: scale(0.8);
    }
`;
const LeavesBackground = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotate(180deg);
`;

const RightPanelWrapper = styled.div``;
const LeftPanelWrapper = styled.div`
    position: relative;
    background: linear-gradient(212.11deg, ${COLOR.white} 40%, ${COLOR.blue} 102%);
`;
const LeftPanelTitle = styled(Typography)`
    text-align: center;
    display: block;

    @media screen and ${GLOBAL_MEDIA_QUERIES.ltablet} {
        width: 24rem;
    }
`;
export const AuthSectionContent = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-width: 30rem;
`;

const LoveText = styled(Typography)``;

const Memoized = memo(AuthLayoutDesktop);
const Styled = styled(Memoized)`
    --love-text-padding: 2rem;
    --padding: 10rem;

    display: grid;
    height: 100vh;
    grid-template-columns: 50% 50%;

    ${FreeQuestLogo} {
        position: absolute;
        top: 2rem;
        left: 2rem;
        height: 3rem;

        & svg {
            transform: scale(0.8);
        }
    }

    ${LeftPanelWrapper}, ${RightPanelWrapper} {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: var(--padding);
        padding-bottom: var(--padding);
        padding-left: calc(var(--padding) / 2);
        padding-right: calc(var(--padding) / 2);

        @media screen and ${GLOBAL_MEDIA_QUERIES.ltablet} {
            padding-left: var(--padding);
            padding-right: var(--padding);
        }
    }

    ${RightPanelWrapper} {
        position: relative;
        padding-bottom: var(--love-text-padding);

        ${AuthSectionContent} {
            padding-bottom: calc(var(--padding) - var(--love-text-padding));
        }
    }

    ${AuthFooter} {
        margin-top: 4rem;
    }
`;
export default Styled;
