export enum Languages {
    EN = 'en',
    PL = 'pl',
    DE = 'de',
}

export enum ApiLanguages {
    EN = 'EN',
    PL = 'PL',
    DE = 'DE',
}
