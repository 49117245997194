import { memo } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';

import * as OAuth2Manager from '@/dataExchange/OAuth2Manager';
import { DEFAULT_PROTECTED_ROUTE } from '@/pages/Routing/Routing';

type IGuestRouteProps = {
    children: JSX.Element;
};

const GuestRoute = ({ children }: IGuestRouteProps) => {
    if (OAuth2Manager.isLoggedIn() === true || OAuth2Manager.isRefreshTokenExisting() === true) {
        return <Navigate to={DEFAULT_PROTECTED_ROUTE} />;
    }
    return children;
};
const Memoized = memo(GuestRoute);
const Styled = styled(Memoized)``;
export default Styled;
