import { ExtraErrorData, ReportingObserver } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { FQ_NODE_ENV } from '@/utils/constants/common';

const initializeSentry = async () => {
    await Sentry.init({
        // d.slaby -> enable sentry on dev only if query param ?sentry=1 is provided
        enabled: !!FQ_NODE_ENV || window.location.search.indexOf('sentry=1') > 0,
        dsn: 'https://62aff8d689da4708aa8d9b4206fd05ef@o1224721.ingest.sentry.io/6517346',
        integrations: [
            new Integrations.BrowserTracing({
                traceFetch: true,
                traceXHR: true,
            }),
            new ExtraErrorData({
                depth: 10,
            }),
            new ReportingObserver(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        environment: FQ_NODE_ENV,
        debug: FQ_NODE_ENV === 'development',
        release: process.env.REACT_APP_LAST_COMMIT_HASH,
        attachStacktrace: true,
        autoSessionTracking: true,
    });
};

export default initializeSentry;
