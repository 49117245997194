import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	p, span, a, input, label, h1, h2, h3, h4, h5, h6, div {
		font-family: 'Roboto';
	}
 	a {
		text-decoration: unset;
		color: black;
	}
	button {
		cursor: pointer;
	}
  i {
    font-style: italic;
  }
  strong {
    font-weight: bold;
  }
`;

export default GlobalStyle;
