import { globalState } from '@/components/Global/GlobalDataManagement';
import { getFetchOptions, getFetchOptionsWithAuth } from '@/dataExchange/utils/fetchOptions';
import { OrganizationDataResponseDto } from '@/domain/common';
import { OrganizationDataParamsDto } from '@/domain/myOrganization';
import {
    MarketingSettingsDto,
    NotificationSettingsParamsDto,
    NotificationSettingsResponseDto,
} from '@/domain/settings';
import { HTTP_METHOD } from '@/utils/enums/dataExchange';

export const sendDeleteOrganizationCode = async (): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/organization/delete-request`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.POST,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return null;
};

export const deleteOrganization = async (params: { token: string }): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/organization`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.DELETE,
            body: params,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return null;
};

export const getOrganizationData = async (): Promise<OrganizationDataResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/organization`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const getTaxCountries = async (): Promise<string[]> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/subscription/countries`,
        getFetchOptions({
            addAuth: false,
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const updateOrganizationData = async (params: OrganizationDataParamsDto): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/organization`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.PUT,
            body: params,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return null;
};

export const getNotificationSettings = async (): Promise<NotificationSettingsResponseDto[]> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/absence-notification-settings/email`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const updateNotificationSettingsOwn = async (
    params: NotificationSettingsParamsDto
): Promise<NotificationSettingsResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/absence-notification-settings/email/own`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.POST,
            body: params,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const updateNotificationSettingsManaged = async (
    params: NotificationSettingsParamsDto
): Promise<NotificationSettingsResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/absence-notification-settings/email/managed`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.POST,
            body: params,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const getMarketingSettings = async (): Promise<MarketingSettingsDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/credential/marketing-agreement`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const updateMarketingSettings = async (params: MarketingSettingsDto): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/credential/marketing-agreement`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.PUT,
            body: params,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return null;
};
