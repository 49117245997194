import i18n from 'i18next';
import React from 'react';
import { I18nextProvider } from 'react-i18next';

import { suspensePromiseWrapper } from '@/utils/suspensePromiseWrapper/suspensePromiseWrapper';

import { setupI18nAsync } from './i18nSetup';

interface II18NLayerProps {
    children: React.ReactNode;
}

const test = setupI18nAsync();
const i18nPromise = suspensePromiseWrapper(test);

const I18NLayer = ({ children }: II18NLayerProps) => {
    const i18nInstance = i18nPromise.read();
    return <I18nextProvider i18n={i18nInstance as typeof i18n}>{children}</I18nextProvider>;
};

export default I18NLayer;
