import { fromUnixTime, getUnixTime, isFuture } from 'date-fns';
import PubSub from 'pubsub-js';

import { AUTH_ACTIONS } from '@/components/Global/GlobalDataManagement/pubsub/authTopic';
import { PUB_SUB_TOPICS } from '@/components/Global/GlobalDataManagement/pubsub/pubsub';
import { getAccessToken, revokeAccessToken } from '@/dataExchange/services/auth.service';
import { GetAccessTokenResponseDto } from '@/domain/auth';
import { initMixpanelProps, resetMixpanel } from '@/mixPanel/mixPanelActions';

export enum LOCAL_STORAGE_KEYS {
    ACCESS_TOKEN = 'AUTH_ACCESS_TOKEN',
    EXPIRES_IN = 'AUTH_EXPIRES_IN',
    REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN',
    ACTIVATED_EMAIL = 'ACTIVATED_EMAIL',
    PAYMENT_COUNTRY = 'COUNTRY',
}

export enum EGRANT_TYPE {
    AUTHORIZATION_CODE = 'password',
    REFRESH_TOKEN = 'refresh_token',
}

export interface IOAuth2Manager {
    urls: {
        loginUrl: string;
        revokeUrl: string;
    };
}

export const setToken = (access_token: string) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, access_token);
};

export const getToken = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
};

export const isLoggedIn = () => {
    const authToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    if (!authToken) return false;
    const tokenExpiration = localStorage.getItem(LOCAL_STORAGE_KEYS.EXPIRES_IN);
    if (!tokenExpiration) return false;
    const isTokenStillValid = isFuture(fromUnixTime(Number(tokenExpiration)));
    return isTokenStillValid;
};
export const isRefreshTokenExisting = () => {
    return !!localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
};

export const logout = async () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    localStorage.clear();
    resetMixpanel();

    if (!accessToken) return;
    revokeAccessToken({
        token: accessToken,
        grant_type: EGRANT_TYPE.AUTHORIZATION_CODE,
    });
    PubSub.publish(PUB_SUB_TOPICS.AUTH, AUTH_ACTIONS.USER_LOGGED_OUT);
};

export const login = async (credentials: { login: string; password: string }) => {
    localStorage.clear();
    const response = await getAccessToken({
        username: credentials.login,
        password: credentials.password,
        grant_type: EGRANT_TYPE.AUTHORIZATION_CODE,
    });
    storeTokens(response);
    initMixpanelProps({ userId: response.user.credentialId, organizationId: response.user.organizationId });
    PubSub.publish(PUB_SUB_TOPICS.AUTH, AUTH_ACTIONS.USER_LOGGED_IN);
};

export const storeTokens = ({ accessToken, refreshToken, accessTokenExpiresAt }: GetAccessTokenResponseDto) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
    localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
    localStorage.setItem(LOCAL_STORAGE_KEYS.EXPIRES_IN, getUnixTime(new Date(accessTokenExpiresAt)).toString());
};

export const setActivatedEmail = (email: string) => localStorage.setItem(LOCAL_STORAGE_KEYS.ACTIVATED_EMAIL, email);
export const getActivatedEmail = () => localStorage.getItem(LOCAL_STORAGE_KEYS.ACTIVATED_EMAIL);

export const setPaymentCountry = (country: string) => localStorage.setItem(LOCAL_STORAGE_KEYS.PAYMENT_COUNTRY, country);
export const getPaymentCountry = () => localStorage.getItem(LOCAL_STORAGE_KEYS.PAYMENT_COUNTRY);
