import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogProps, Typography } from '@mui/material';
import { memo, ReactChild } from 'react';
import styled, { css } from 'styled-components';

import { FQ_BUTTON_VARIANTS } from '@/components/muiCustomized/FQButton/constant';
import FQButton from '@/components/muiCustomized/FQButton/FQButton';
import { COLOR, COLOR_FUNCTION } from '@/globalStyles';

export interface Action {
    label: string;
    onClick?(): void;
    disabled?: boolean;
}

export interface IModalProps extends DialogProps {
    className?: string;
    onClose?: () => void;
    title?: string;
    children: ReactChild;
    hideHeader?: boolean;
    primaryAction?: Action;
    secondaryAction?: Action;
    hideCloseIcon?: boolean;
    hero?: JSX.Element;
    heroTitle?: boolean;
}

const Modal = ({
    className,
    title,
    onClose,
    children,
    hideHeader,
    primaryAction,
    secondaryAction,
    hideCloseIcon,
    hero,
    heroTitle,
    ...props
}: IModalProps) => {
    return (
        <Dialog data-testid="dialog" className={className} {...props} onClose={onClose} scroll="body">
            <div>
                {!hideHeader && (
                    <ModalHeader withoutSeparator={!hero} stacked={!!hero || !!heroTitle}>
                        <ModalHeaderClose>{!hideCloseIcon && <StyledCloseIcon onClick={onClose} />}</ModalHeaderClose>
                        <ModalHeaderTitle heroTitle={!!heroTitle}>
                            {hero}
                            {title ? <Typography variant="modal_or_card_title">{title}</Typography> : null}
                        </ModalHeaderTitle>
                    </ModalHeader>
                )}
                <ModalBody>
                    {children}
                    {primaryAction && (
                        <ButtonsContainer>
                            {secondaryAction && (
                                <FQButton
                                    color="fqRed"
                                    data-variant={FQ_BUTTON_VARIANTS.fqOutline}
                                    onClick={secondaryAction?.onClick}
                                >
                                    <Typography>{secondaryAction.label}</Typography>
                                </FQButton>
                            )}
                            <FQButton
                                color="fqRed"
                                data-variant={FQ_BUTTON_VARIANTS.fqPrimary}
                                onClick={primaryAction.onClick}
                                disabled={primaryAction.disabled}
                            >
                                <Typography>{primaryAction.label}</Typography>
                            </FQButton>
                        </ButtonsContainer>
                    )}
                </ModalBody>
            </div>
        </Dialog>
    );
};

export const ModalBody = styled.div`
    padding: 2rem;
    color: ${COLOR.stormGray};
`;

export const ModalHeader = styled.div<{ withoutSeparator: boolean; stacked: boolean }>`
    padding: 1.25rem 2rem;
    ${props =>
        !props.withoutSeparator &&
        css`
            padding-bottom: 0;
        `};
    display: flex;
    flex-direction: ${props => (props.stacked ? `column` : `row-reverse`)};
    flex-wrap: nowrap;
    justify-content: ${props => (props.withoutSeparator ? `space-between` : `center`)};
    ${props =>
        props.withoutSeparator &&
        css`
            border-bottom: 1px solid ${COLOR_FUNCTION.border};
        `}
`;

const ModalHeaderTitle = styled.div<{ heroTitle: boolean }>`
    ${props =>
        props.heroTitle &&
        css`
            padding-top: 2rem;
        `}
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
`;

export const ModalHeaderClose = styled.div`
    align-self: end;
`;

const StyledCloseIcon = styled(CloseIcon)`
    margin-left: auto;
    cursor: pointer;
`;

const ButtonsContainer = styled.div`
    padding-top: 2.5rem;
    display: flex;
    gap: 1rem;
    justify-content: center;

    > button {
        min-width: 7.5rem;
    }
`;

const Memoized = memo(Modal);
const Styled = styled(Memoized)`
    .MuiDialog-paper {
        max-width: 80vw;
        box-shadow: none;
        border-radius: 8px;
    }
`;

export default Styled;
