import { globalState } from '@/components/Global/GlobalDataManagement';
import { getFetchOptions, getFetchOptionsWithAuth } from '@/dataExchange/utils/fetchOptions';
import { SubscriptionPlanResponseDto, SubscriptionStatusResponseDto } from '@/domain/subscription';
import { HTTP_METHOD } from '@/utils/enums/dataExchange';

export const getSubscriptionsPlans = async (): Promise<SubscriptionPlanResponseDto[]> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/subscription/plans`,
        getFetchOptions({
            addAuth: false,
            contentType: null,
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const getSubscriptionStatus = async (): Promise<SubscriptionStatusResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/subscription/status`,
        getFetchOptionsWithAuth({
            contentType: null,
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const createCheckoutSession = async ({
    subscriptionPriceId,
    landingPageRedirectUrls,
}: {
    subscriptionPriceId: string;
    landingPageRedirectUrls?: boolean;
}): Promise<string> => {
    const response = await fetch(
        `${
            globalState.config.FQ_BASE_API_URL
        }/subscription/create-checkout-session?subscriptionPriceId=${subscriptionPriceId}${
            landingPageRedirectUrls !== undefined && `&landingPageRedirectUrls=${landingPageRedirectUrls}`
        }`,
        getFetchOptionsWithAuth({
            contentType: null,
            method: HTTP_METHOD.POST,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.text();
};

export const getCustomerPortalUrl = async (): Promise<string> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/subscription/get-customer-portal-url`,
        getFetchOptionsWithAuth({
            contentType: null,
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.text();
};
