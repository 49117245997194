import fetchIntercept from 'fetch-intercept';
import PubSub from 'pubsub-js';

import { AUTH_ACTIONS } from '@/components/Global/GlobalDataManagement/pubsub/authTopic';
import { PUB_SUB_TOPICS } from '@/components/Global/GlobalDataManagement/pubsub/pubsub';
import { ROUTES } from '@/pages/Routing/Routing';

export const unregister = fetchIntercept.register({
    request: function (url, config) {
        // Modify the url or config here
        return [url, config];
    },

    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },

    response: function (response) {
        if (response.status === 401) {
            PubSub.publish(PUB_SUB_TOPICS.AUTH, AUTH_ACTIONS.USER_LOGGED_OUT);
            window.location.replace(`${window.origin}${ROUTES.LOGOUT}`);
        }
        return response;
    },

    responseError: function (error) {
        // Handle an fetch error
        return Promise.reject(error);
    },
});
