import i18n from 'i18next';

import { globalState } from '@/components/Global/GlobalDataManagement';
import { getFetchOptionsWithAuth } from '@/dataExchange/utils/fetchOptions';
import {
    AbsenceLimitGlobalParamsDto,
    AbsenceLimitGlobalResponseDto,
    AbsenceLimitRestrictionGlobalResponseDto,
    AbsenceTypeLimitResponseDto,
    AbsenceTypeParamsDto,
    AbsenceTypeResponseDto,
    AbsenceTypeResponseListDto,
    AvailableCountriesDaysResponseDto,
    AvailableHolidaysResponseDto,
    CreateAbsenceTypeParamsDto,
    OrganizationDaysConfigurationResponseDto,
    OrganizationHolidayResponseDto,
    UpdateAbsenceTypeLimitParamsDto,
    UpdateAbsenceTypeParamsDto,
} from '@/domain/myOrganization';
import { UserTeamResponseDto } from '@/domain/user';
import { PERIOD } from '@/utils/enums/common';
import { HTTP_METHOD } from '@/utils/enums/dataExchange';

export const getOrganizationHolidays = async (): Promise<OrganizationHolidayResponseDto[]> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/organization-day-configuration`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const getAbsenceTypes = async (withArchived: boolean): Promise<AbsenceTypeResponseDto[]> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/absence-type${withArchived ? `?withArchived=${withArchived}` : ''}`,
        getFetchOptionsWithAuth({
            contentType: null,
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const getAbsenceTypesNew = async (withArchived: boolean): Promise<AbsenceTypeResponseListDto[]> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/v2/absence/limits/organization/summary?withArchived=${
            withArchived ? 'true' : 'false'
        }}`,
        getFetchOptionsWithAuth({
            contentType: null,
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const getAvailableCountriesDays = async (): Promise<AvailableCountriesDaysResponseDto[]> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/holidays/countries`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    const countryNames = new Intl.DisplayNames([i18n.resolvedLanguage ?? 'EN'], { type: 'region' });
    const countries = await response.json();

    const countriesWithTranslatedNames = countries.map(country => ({
        ...country,
        name: countryNames.of(country.countryCode),
    }));

    return countriesWithTranslatedNames;
};

export const getAvailableHolidays = async (
    countryCode: string,
    year: string
): Promise<AvailableHolidaysResponseDto[]> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/holidays/public/${countryCode}?year=${year}`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const getSpecificAbsenceType = async (absenceTypeId: string): Promise<AbsenceTypeResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/absence-type/${absenceTypeId}`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const getLimitsGlobal = async (): Promise<AbsenceLimitGlobalResponseDto[]> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/v2/absence/limits/global`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) throw new Error();
    return response.json();
};

export const updateAbsenceType = async ({
    absenceTypeData,
    absenceTypeId,
}: {
    absenceTypeData: UpdateAbsenceTypeParamsDto;
    absenceTypeId: string;
}): Promise<AbsenceTypeResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/absence-type/${absenceTypeId}`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.PUT,
            body: absenceTypeData,
        })
    );

    if (!response.ok) throw new Error();
    return response.json();
};

export const updateAbsenceTypeLimit = async ({
    absenceTypeLimitData,
    absenceTypeLimitId,
}: {
    absenceTypeLimitData: UpdateAbsenceTypeLimitParamsDto;
    absenceTypeLimitId: string;
}): Promise<AbsenceTypeLimitResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/v2/absence/type/limit/${absenceTypeLimitId}`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.PUT,
            body: absenceTypeLimitData,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const createAbsenceType = async (
    absenceTypeData: CreateAbsenceTypeParamsDto
): Promise<AbsenceTypeResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/absence-type`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.POST,
            body: absenceTypeData,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const createAbsenceTypeNew = async (absenceTypeData: AbsenceTypeParamsDto): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/absence-type`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.POST,
            body: absenceTypeData,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const updateAbsenceTypeNew = async (absenceTypeData: AbsenceTypeParamsDto, id: string): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/absence-type/${id}`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.PUT,
            body: absenceTypeData,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const createAbsenceTypeLimit = async (
    absenceTypeLimitData: UpdateAbsenceTypeLimitParamsDto
): Promise<AbsenceTypeLimitResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/v2/absence/type/limit`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.POST,
            body: absenceTypeLimitData,
        })
    );

    if (!response.ok) throw new Error();
    return response.json();
};

export const deleteAbsenceType = async (absenceTypeId: string) => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/absence-type/${absenceTypeId}`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.DELETE,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response;
};

export const getAllTeams = async (): Promise<UserTeamResponseDto[]> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/team`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const addUserToTeam = async (params: { credentialId: string; teamId: string }): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/team/user`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.POST,
            body: params,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const removeUserFromTeam = async (params: { credentialId: string; teamId: string }): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/team/user`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.DELETE,
            body: params,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const addApproverToUser = async (params: { credentialId: string; managerId: string }): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/user-absence-manager`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.POST,
            body: params,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const removeApproverFromUser = async (pairedId: string): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/user-absence-manager/${pairedId}`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.DELETE,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const createOrganizationDayOff = async (params: {
    recurringType: string;
    date: string;
    description: string;
}): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/organization-day-configuration`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.POST,
            body: params,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const getOrganizationDaysOff = async (): Promise<OrganizationDaysConfigurationResponseDto[]> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/organization-day-configuration`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const deleteOrganizationDayOff = async (id: string) => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/organization-day-configuration/${id}`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.DELETE,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response;
};

export const getAbsenceGlobalLimits = async (): Promise<AbsenceTypeLimitResponseDto[]> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/v2/absence/limits/global`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const getAbsenceGlobalLimit = async ({
    absenceLimitId,
}: {
    absenceLimitId: string;
}): Promise<AbsenceTypeLimitResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/v2/absence/limits/global/${absenceLimitId}`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const createAbsenceLimitGlobal = async (
    params: AbsenceLimitGlobalParamsDto
): Promise<AbsenceLimitGlobalResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/v2/absence/limits/global`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.POST,
            body: params,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const updateAbsenceLimitGlobal = async (
    params: AbsenceLimitGlobalParamsDto,
    absenceLimitId: string
): Promise<AbsenceLimitGlobalResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/v2/absence/limits/global/${absenceLimitId}`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.PUT,
            body: params,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const deleteAbsenceLimitGlobal = async (absenceLimitId: string): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/v2/absence/limits/global/${absenceLimitId}`,
        getFetchOptionsWithAuth({
            contentType: null,
            method: HTTP_METHOD.DELETE,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return null;
};

export const createAbsenceLimitRestrictionGlobal = async (params: {
    hasRestriction: boolean;
    restriction: number;
    restrictionPeriodType: string | PERIOD;
}): Promise<AbsenceLimitRestrictionGlobalResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/v2/absence/limits/restrictions/global`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.POST,
            body: params,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const updateAbsenceLimitRestrictionGlobal = async (
    params: {
        hasRestriction: boolean;
        restriction: number;
        restrictionPeriodType: string | PERIOD;
    },
    id
): Promise<AbsenceLimitRestrictionGlobalResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/v2/absence/limits/restrictions/global/${id}`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.PUT,
            body: params,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const getAbsenceLimitRestrictionsGlobal = async (): Promise<AbsenceLimitRestrictionGlobalResponseDto[]> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/v2/absence/limits/restrictions/global`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const getAbsenceLimitRestrictionGlobal = async ({
    absenceLimitRestrictionId,
}: {
    absenceLimitRestrictionId: string;
}): Promise<AbsenceLimitRestrictionGlobalResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/v2/absence/limits/restrictions/global/${absenceLimitRestrictionId}`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.GET,
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};
