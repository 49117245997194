import * as queryString from 'query-string';

import { globalState } from '@/components/Global/GlobalDataManagement';
import { enhanceUserData } from '@/dataExchange/utils/enhanceUserData';
import { getFetchOptionsWithAuth } from '@/dataExchange/utils/fetchOptions';
import { parseResponseImage } from '@/dataExchange/utils/parseResponseImage';
import { ApiResponseWithPagination, UserDataResponseDto } from '@/domain/common';
import { UpdatePasswordRequestDto } from '@/domain/generated';
import {
    AllUsersDataParamsDto,
    ProfilePictureType,
    UpdateOwnNamesParamsDto,
    UserDataResponse,
    UserTeamResponseDto,
} from '@/domain/user';
import { ACCOUNT_ROLE, ORDER, ORDER_BY, TOGGLE_BAN_ACTION } from '@/utils/enums/common';
import { CONTENT_TYPE, HTTP_METHOD } from '@/utils/enums/dataExchange';

export const getUserData = async (params: {
    credentialId: string;
    withLimits?: boolean;
}): Promise<UserDataResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/user/${params.credentialId}?withLimits=${params.withLimits ?? false}`,
        getFetchOptionsWithAuth({ method: HTTP_METHOD.GET })
    );

    return enhanceUserData(await response.json());
};

export const getWhoAmI = async (): Promise<UserDataResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/credential/whoami`,
        getFetchOptionsWithAuth({ method: HTTP_METHOD.GET })
    );

    return enhanceUserData(await response.json());
};

export const getUserPicture = async (imgUrl?: string | null): Promise<ProfilePictureType> => {
    if (!imgUrl) return null;
    // This should be moved into Fetch Controller
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 5000);
    const response = await fetch(imgUrl, {
        ...getFetchOptionsWithAuth({ method: HTTP_METHOD.GET, contentType: CONTENT_TYPE.IMAGE }),
        signal: controller.signal,
    });
    clearTimeout(id);

    if (!response?.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    const imageUrl = await parseResponseImage(response);
    return imageUrl;
};

export const getAllUsers = async (
    params: AllUsersDataParamsDto
): Promise<ApiResponseWithPagination<UserDataResponse[]>> => {
    const paramsWithDefaultOrdering = {
        orderBy: ORDER_BY.FIRST_NAME,
        order: ORDER.ASC,
        ...params,
    };
    const queryParams = queryString.stringify(paramsWithDefaultOrdering, { skipEmptyString: true, skipNull: true });

    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/v2/user?${queryParams}`,
        getFetchOptionsWithAuth({ method: HTTP_METHOD.GET })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    const parsedResponse = (await response.json()) as ApiResponseWithPagination<UserDataResponse[]>;
    parsedResponse.elements.forEach(enhanceUserData);

    return parsedResponse;
};

export const updateOwnNames = async (params: UpdateOwnNamesParamsDto): Promise<boolean> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/credential/update/personal`,
        getFetchOptionsWithAuth({ method: HTTP_METHOD.PUT, body: params })
    );

    if (!response.ok) {
        const responseBody = await response.json();
        throw responseBody;
    }

    return response.ok;
};

export const uploadProfilePicture = async (formData: FormData): Promise<boolean> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/credential-file/upload-profile-picture`,
        getFetchOptionsWithAuth({ contentType: null, method: HTTP_METHOD.POST, body: formData })
    );

    if (!response.ok) {
        const responseBody = await response.json();
        throw responseBody;
    }

    return response.ok;
};

export const changePassword = async (passwords: UpdatePasswordRequestDto): Promise<boolean> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/credential/update-password`,
        getFetchOptionsWithAuth({ method: HTTP_METHOD.POST, body: passwords })
    );

    if (!response.ok) {
        const responseBody = await response.json();
        throw responseBody;
    }

    return response.ok;
};

export const getUserTeams = async (credentialId: string): Promise<UserTeamResponseDto[]> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/team/user/${credentialId}`,
        getFetchOptionsWithAuth({ method: HTTP_METHOD.GET })
    );

    if (!response.ok) {
        const responseBody = await response.json();
        throw responseBody;
    }

    return response.json();
};

export const addUserRole = async ({ userId, role }: { userId: string; role: ACCOUNT_ROLE }): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/user/${userId}/addRole`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.PUT,
            body: { role },
        })
    );

    if (!response.ok) {
        const responseBody = await response.json();
        throw responseBody;
    }

    return null;
};

export const removeUserRole = async ({ userId, role }: { userId: string; role: ACCOUNT_ROLE }): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/user/${userId}/removeRole`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.PUT,
            body: { role },
        })
    );

    if (!response.ok) {
        const responseBody = await response.json();
        throw responseBody;
    }

    return null;
};

export const toggleUserBan = async ({
    userId,
    banToggle,
}: {
    userId: string;
    banToggle: TOGGLE_BAN_ACTION;
}): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/account/${userId}/ban-toggle`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.POST,
            body: { banToggle },
        })
    );

    if (!response.ok) {
        const responseBody = await response.json();
        throw responseBody;
    }

    return null;
};
