import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';

import { GLOBAL_MEDIA_QUERIES } from '@/globalStyles';
import TwoPeople from '@/resources/image/two_human_mountain.svg';

import { Info, Title } from './styles';

const SuccessContent = () => {
    const { t } = useTranslation();
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
    const content = matches.ltablet
        ? t('stripe_status_success_desktop_content')
        : t('stripe_status_success_mobile_content');

    return (
        <>
            <img src={TwoPeople} alt="people" width={280} />
            <Title>{t('register_we_got_it')}</Title>
            <Info>{content}</Info>
        </>
    );
};

export default SuccessContent;
