import RefreshIcon from '@mui/icons-material/Refresh';
import { Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const OutdatedMessage = () => {
    const { t } = useTranslation();
    const handleClick = e => {
        e.preventDefault();
        window.location.reload();
    };

    return (
        <Wrapper>
            <Typography>{t('web_newVersionDeployed_alert')}</Typography>
            <ReloadButton onClick={handleClick}>
                <RefreshIcon fontSize="small" />
                <div>{t('web_pageReload_button')}</div>
            </ReloadButton>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    width: 20rem;
`;
const ReloadButton = styled.button`
    display: flex;
    gap: 0.3rem;
    align-items: center;
    border: none;
    color: inherit;
    background-color: transparent;
    text-transform: uppercase;
    padding: 0;
    margin: 0;

    &:hover {
        text-decoration: underline;
    }
`;

const Memoized = memo(OutdatedMessage);
const Styled = styled(Memoized)`
    .MuiDialog-paper {
        max-width: 80vw;
    }
`;

export default Styled;
