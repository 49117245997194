import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { memo } from 'react';
import styled from 'styled-components';

import { COLOR } from '@/globalStyles';

interface IInlineLoaderProps extends CircularProgressProps {
    className?: string;
    size?: number | string;
}

const InlineLoader = (props: IInlineLoaderProps) => {
    return <CircularProgress {...props} />;
};

const Memoized = memo(InlineLoader);
const Styled = styled(Memoized)`
    color: ${COLOR.spicyPink};
    z-index: 11;
`;
export default Styled;
