export const ADMINISTRATOR_PANEL_ROUTES = {
    requestTypes: 'request-types',
    daysOff: 'days-off',
    teams: 'teams',
    employees: 'employees',
    invitations: 'invitations',
    limits: 'limits',
    subscriptions: 'subscriptions',
    organizationData: 'organizationData',
    manageRequests: 'manage-requests',
    reports: 'reports',
};
