import { useContext } from 'react';

import { GlobalDataContext } from '@/components/Global/GlobalDataManagement/GlobalDataManagement';
import { ExtendedUserData } from '@/domain/common';
import { ACCOUNT_ROLE } from '@/utils/enums/common';

const useLoggedInUserData = (): ExtendedUserData | Record<string, never> => {
    const globalData = useContext(GlobalDataContext);
    const userData = globalData?.state?.loggedInUser;

    if (!userData) return {};

    const isAdmin = userData?.roles?.some(({ role }) => role === ACCOUNT_ROLE.ROLE_ADMINISTRATOR);
    const isLead = userData?.roles?.some(({ role }) => role === ACCOUNT_ROLE.ROLE_MANAGER);
    const isCompanyOwner = userData?.roles?.some(({ role }) => role === ACCOUNT_ROLE.ROLE_COMPANY_OWNER);

    const userFullName = `${userData?.firstName} ${userData?.lastName}`;

    return { ...userData, isAdmin, isLead, isCompanyOwner, userFullName };
};

export default useLoggedInUserData;
