import styled from 'styled-components';

import { COLOR, FONT_SIZE, FONT_WEIGHT } from '@/globalStyles';

export const Title = styled.div`
    text-align: center;
    color: ${COLOR.eerieBlack};
    line-height: 24px;
    letter-spacing: 0.7px;
    font-weight: ${FONT_WEIGHT.medium};
    margin-top: 1rem;
`;

export const Info = styled.div`
    width: 293px;
    text-align: center;
    color: ${COLOR.suvaGrey};
    line-height: 21px;
    letter-spacing: 0.2px;
    font-size: ${FONT_SIZE.smedium};
`;
