import React, { memo } from 'react';
import styled from 'styled-components';

import { AuthFooter } from '@/components/Global/layouts/AuthLayout/AuthFooter';
import { GLOBAL_MEDIA_QUERIES } from '@/globalStyles';

import { IAuthLayoutProps } from './AuthLayout';

type IAuthLayoutMobileProps = IAuthLayoutProps;

const AuthLayoutMobile = ({ className, children }: IAuthLayoutMobileProps) => {
    return (
        <div className={className}>
            <BodyWrapper>{children}</BodyWrapper>
            <AuthFooter addEnjoyText />
        </div>
    );
};

const BodyWrapper = styled.div`
    margin-bottom: 2rem;
`;

const Memoized = memo(AuthLayoutMobile);
const Styled = styled(Memoized)`
    margin: 2.5rem;
    text-align: center;
    @media screen and ${GLOBAL_MEDIA_QUERIES.ltablet} {
        margin: unset;
        text-align: unset;
    }
`;
export default Styled;
