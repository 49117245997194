export const installSW = async () => {
    const noCache = window.location.search.indexOf('cache=0') > 0;

    if (noCache) {
        try {
            console.log('Clearing cache!');
            await removeAllServiceWorkers(noCache);
            await clearAppCache(noCache);
        } catch (error) {
            console.error('Error during cache clear sequence:', error);
        } finally {
            return;
        }
    }
    // Check that service workers are supported
    if ('serviceWorker' in navigator) {
        // Use the window load event to keep the page load performant
        navigator.serviceWorker.register('/serviceWorker.js').then(reg => reg.update());
    }
};

export const removeAllServiceWorkers = async (isDev = false) => {
    const activeSw = await navigator.serviceWorker.getRegistrations();
    activeSw.forEach(sw => sw.unregister());
    if (!isDev) {
        return;
    }
    console.debug('%c UNREGISTERING SERVICE WORKER COMPLETE ', 'color: black;background-color: #87f356;');
};

export const clearAppCache = async (isDev = false) => {
    const cacheNames = await caches.keys();
    cacheNames.forEach(cacheName => caches.delete(cacheName));
    if (!isDev) {
        return;
    }
    console.debug('%c CLEARING CACHE STORAGE COMPLETE ', 'color: black;background-color: #87f356;');
};
