import CloseIcon from '@mui/icons-material/Close';
import { SnackbarKey } from 'notistack';
import styled from 'styled-components';

import { FQ_BUTTON_VARIANTS } from '@/components/muiCustomized/FQButton/constant';
import FQButton from '@/components/muiCustomized/FQButton/FQButton';

interface ICustomAlertAction {
    actionKey: SnackbarKey;
    onCustomAction: () => void;
    onClose: (id) => void;
    actionLabel: string;
}

export const CustomAlertAction = ({ actionKey, onCustomAction, onClose, actionLabel }: ICustomAlertAction) => {
    return (
        <>
            <CustomActionButton data-variant={FQ_BUTTON_VARIANTS.fqOutline} onClick={onCustomAction}>
                {actionLabel}
            </CustomActionButton>
            <CloseAction onClick={() => onClose(actionKey)} />
        </>
    );
};

export const CloseAction = styled(CloseIcon)`
    cursor: pointer;
    margin-right: 0.5rem;
`;

const CustomActionButton = styled(FQButton)`
    color: inherit !important;
    margin-right: 0.7rem !important;
`;
