export const FQ_NODE_ENV = process.env.REACT_APP_FQ_NODE_ENV;
export const FQ_BASE_API_URL = process.env.REACT_APP_FQ_BASE_API_URL;
export const APP_VERSION = process.env.REACT_APP_LAST_COMMIT_HASH;
export const MIXPANEL_TOKEN_PRODUCTION = '5601f0ba756ecf02b96f55c0e09cf802';
export const MIXPANEL_TOKEN_STAGE = '9e6b985a21da4f21e5a9af3f2d555af5';
export const MIXPANEL_TOKEN_DEV = 'ad4d6597e74ff648013f94763395fa94';
export const APP_RELEASE_VERSION = '3.16';
export const HOT_JAR_ID_DEV = '3854362';
export const HOT_JAR_ID_PROD = '3840341';
export const HOTJAR_SNIPPET_VERSION = 6;

export const FIRST_PAGE = 1;
export const INITIAL_PAGINATION = {
    page: FIRST_PAGE,
    pageSize: 10,
};

export const LETTERS_ONLY_REGEX = /^[\p{L}]+$/u;
export const MAX_PAGE_SIZE = 5000;
export const LOADER_SIZE = {
    small: 20,
};
export const SEARCH_DEBOUNCE_TIME = 500;
export const LOCALE_NAME_LENGTH = 2;

//New request summary
export const DEFAULT_NUMBER_OF_DAYS = 0;
export const ONE_DAY = 1;
