import { AUTH_ROUTES } from 'pages/Auth/Auth';
import { lazy, memo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { createModal } from '@/components/common/Modal/ModalBuilder';
import { ModalContext } from '@/components/common/Modal/ModalProvider';
import AuthLayout from '@/components/Global/layouts/AuthLayout/AuthLayout';
import useLoggedInUserData from '@/hooks/useUserData/useLoggedInUserData';
import hotjarScript from '@/hotjar/hotjar';
import { MIXPANEL_ACTIONS, mixpanelTracking } from '@/mixPanel/mixPanelActions';
import { ADMINISTRATOR_PANEL_ROUTES } from '@/pages/AdministratorPanel/constants';
import ProtectedRoute from '@/pages/Routing/ProtectedRoute/ProtectedRoute';
import ActivatedRedirectRoute from '@/pages/Routing/RedirectRoute/ActivatedRedirectRoute';
import StripePaymentStatusRoute from '@/pages/Routing/RedirectRoute/StripePaymentStatusRoute';

const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ 'pages/Dashboard/Dashboard'));
const Employees = lazy(() => import(/* webpackChunkName: "Employees" */ 'pages/Employees/Employees'));
const MyProfile = lazy(() => import(/* webpackChunkName: "Dashboard" */ 'pages/Profile/MyProfile/MyProfile'));
const UserProfile = lazy(() => import(/* webpackChunkName: "Dashboard" */ 'pages/Profile/UserProfile/UserProfile'));
const AdministratorPanel = lazy(
    () => import(/* webpackChunkName: "AdministratorPanel" */ '@/pages/AdministratorPanel/AdministratorPanel')
);
const NewRequest = lazy(() => import(/* webpackChunkName: "NewRequest" */ 'pages/NewRequest/NewRequest'));
const UserRequests = lazy(
    () => import(/* webpackChunkName: "UserRequests" */ '@/pages/Requests/UserRequests/UserRequests')
);
const LeadRequests = lazy(
    () => import(/* webpackChunkName: "AdminRequests" */ '@/pages/Requests/LeadRequests/LeadRequests')
);
const Settings = lazy(() => import(/* webpackChunkName: "NewRequest" */ 'pages/Settings/Settings'));
const Auth = lazy(() => import(/* webpackChunkName: "Auth" */ 'pages/Auth/Auth'));
const Page404 = lazy(() => import(/* webpackChunkName: "Page404" */ 'pages/Page404/Page404'));
const Logout = lazy(() => import(/* webpackChunkName: "Logout" */ 'pages/Logout/Logout'));
const RegisterOrganizationAndUser = lazy(
    () =>
        import(
            /* webpackChunkName: "RegisterOrganizationAndUser" */ '@/pages/Auth/RegisterOrganizationAndUser/RegisterOrganizationAndUser'
        )
);
const Payment = lazy(
    () => import(/* webpackChunkName: "Payment" */ '@/pages/Auth/RegisterOrganizationAndUser/PlanPayment/Payment')
);
const StripePaymentResult = lazy(
    () => import(/* webpackChunkName: "Payment" */ '@/pages/Auth/StripePaymentResult/StripePaymentResult')
);

interface IRoutingProps {
    className?: string;
}

export enum ROUTES {
    DEFAULT_ROUTE = '/',
    MY_PROFILE = '/my-profile',
    USER_PROFILE = '/user-profile/:userId',
    DASHBOARD = '/dashboard',
    EMPLOYEES = '/employees',
    REQUESTS = '/requests',
    NEW_REQUEST = '/new-request/:userId',
    ADMINISTRATOR_PANEL = '/administrator-panel',
    FORGET_PASSWORD = '/forget-password',
    SETTINGS = '/settings',
    AUTH = '/auth',
    PAGE_NOT_FOUND = '/404',
    LOGOUT = '/logout',
    PAYMENT = '/payment',
}
export const DEFAULT_GUEST_ROUTE = `${ROUTES.AUTH}${AUTH_ROUTES.LOGIN}`;
export const DEFAULT_PROTECTED_ROUTE = ROUTES.DASHBOARD;
const SUBSCRIPTIONS_ROUTE = `${ROUTES.ADMINISTRATOR_PANEL}/${ADMINISTRATOR_PANEL_ROUTES.subscriptions}`;

export const trimPath = (path: string) => `${process.env.PUBLIC_URL}${path}`;

const useNonAdminWarningModal = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { open, close } = useContext(ModalContext);

    const warningModal = createModal()
        .withHeroTitle(t('common_one_moment'))
        .withContent(t('administratorPanel_subscription_not_enough_rights'))
        .withPrimaryAction({
            label: t('button_text_close'),
            onClick: () => close(),
        })
        .withSecondaryAction({
            label: t('common_logout'),
            onClick: () => {
                close();
                navigate(ROUTES.LOGOUT);
            },
        });

    const openWarningModal = () => open(warningModal);

    return openWarningModal;
};

const Routing = ({ className }: IRoutingProps) => {
    const { isLead, isAdmin } = useLoggedInUserData();

    useEffect(() => {
        const isAccessToken = localStorage.getItem('AUTH_ACCESS_TOKEN');
        const isRefreshToken = localStorage.getItem('AUTH_REFRESH_TOKEN');
        const isAuthAccessToken = localStorage.getItem('AUTH_ACCESS_TOKEN');
        isAccessToken && isRefreshToken && isAuthAccessToken && mixpanelTracking(MIXPANEL_ACTIONS.OPEN_APP);
        document.head.appendChild(hotjarScript);
    }, []);

    const openWarningModal = useNonAdminWarningModal();

    return (
        <div className={className}>
            <Routes>
                <Route
                    path={ROUTES.DASHBOARD}
                    element={
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES.EMPLOYEES}
                    element={
                        <ProtectedRoute>
                            <Employees />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES.MY_PROFILE}
                    element={
                        <ProtectedRoute>
                            <MyProfile />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES.USER_PROFILE}
                    element={
                        <ProtectedRoute>
                            <UserProfile />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path={`${ROUTES.ADMINISTRATOR_PANEL}/*`}
                    element={
                        <StripePaymentStatusRoute>
                            <ProtectedRoute isAdminRoute onReject={openWarningModal}>
                                <AdministratorPanel />
                            </ProtectedRoute>
                        </StripePaymentStatusRoute>
                    }
                />
                <Route
                    path={`${ROUTES.REQUESTS}/*`}
                    element={
                        <ProtectedRoute>
                            {isLead || isAdmin ? <LeadRequests showAcceptTab={isLead} /> : <UserRequests />}
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES.NEW_REQUEST}
                    element={
                        <ProtectedRoute>
                            <NewRequest />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES.SETTINGS}
                    element={
                        <ProtectedRoute>
                            <Settings />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={`${ROUTES.AUTH}${AUTH_ROUTES.REGISTER_ORGANIZATION_AND_USER}`}
                    element={
                        <ActivatedRedirectRoute path={SUBSCRIPTIONS_ROUTE}>
                            <AuthLayout>
                                <RegisterOrganizationAndUser />
                            </AuthLayout>
                        </ActivatedRedirectRoute>
                    }
                />
                <Route
                    path={`${ROUTES.AUTH}${AUTH_ROUTES.REGISTER_ORGANIZATION_AND_USER}${ROUTES.PAYMENT}/*`}
                    element={<Payment />}
                />
                <Route path={`${ROUTES.AUTH}${AUTH_ROUTES.STRIPE_STATUS}/*`} element={<StripePaymentResult />} />
                <Route path={`${ROUTES.AUTH}/*`} element={<Auth />} />
                {/* Temporal route until the One-go-flow would become dominant for org registration process */}
                <Route path={ROUTES.PAGE_NOT_FOUND} element={<Page404 />} />
                <Route path={ROUTES.LOGOUT} element={<Logout />} />
                <Route path={ROUTES.DEFAULT_ROUTE} element={<Navigate to={DEFAULT_GUEST_ROUTE} />} />
                <Route path="*" element={<Navigate to={ROUTES.PAGE_NOT_FOUND} />} />
            </Routes>
        </div>
    );
};

const Memoized = memo(Routing);
const Styled = styled(Memoized)``;
export default Styled;
