import ReactDOM from 'react-dom';

import InitializeApplication from '@/components/Global/InitializeApplication/InitializeApplication';

import { APP_VERSION, FQ_NODE_ENV } from './utils/constants/common';

console.log(
    `%cCurrent App version: ${APP_VERSION}, Node env: ${FQ_NODE_ENV}`,
    'background: black; color:orange; padding: 1rem;'
);

ReactDOM.render(<InitializeApplication />, document.getElementById('root'));
