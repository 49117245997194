import { rem } from 'polished';
import { css } from 'styled-components';

export const COLOR = {
    black: '#000000',
    eerieBlack: '#222121',
    blue: '#2095D3',
    lightBlue: '#8AC4E4FF',
    lightSkyBlue: '#BEE6FC',
    powderBlue: '#B0E0E6',
    coolMint: '#D9F1FC',
    hostaBlue: '#77BFC7',
    aliceBlue: '#F1F9FF',
    grayBlue: '#F1F6FC',
    lightNavyBlue: '#3F8CD3',
    navyBlue: '#1976D2',
    pattensBlue: '#E0E3E4',
    platinum: '#E5E4E2',
    dirtyWhite: '#F0F0F0',
    dirtyWhite2: '#F4EFF4',
    snow: '#F9F9F9',
    white: '#FFFFFF',
    transparentWhite: '#FFFFFFB5',
    green: '#18D596',
    greenGrass: '#43A148',
    pastelMint: '#DAFCD9',
    yellow: '#FEC632',
    yellow2: '#F4BA49',
    lightYellow: '#FFE5A1',
    darkYellow: '#DCA000',
    blackPearl: '#1A1B1C',
    silver: '#BCBCBC',
    lightPersianRed: '#ED384D',
    persianRed: '#d32f2f',
    lightRed: '#EC4343',
    red: '#F94157',
    redWildWatermelon: '#F85467',
    spicyPink: '#E626BB',
    zambezi: '#595959',
    mediumGray: '#626E77',
    stormGray: '#717684',
    suvaGrey: '#908F8F',
    ghost: '#C4C4C6',
    darkGray: '#878F95',
    lightGray: '#E9E9E9',
    darkGrayishRed: '#908f8f',
    veryDarkGrayishRed: '#5A5959',
    paynesGrayTransparent: '#3C3C4380',
    linkWater: '#C3C8CC',
    whisper: '#EAEAEA',
    mountainMeadow: '#1CA673',
};

export const COLOR_FUNCTION = {
    primary: COLOR.redWildWatermelon,
    border: COLOR.dirtyWhite,
    borderSecondary: COLOR.ghost,
    boxBorder: COLOR.lightGray,
    selected: COLOR.blue,
    separator: COLOR.dirtyWhite,
    icon: COLOR.darkGray,
    placeholder: COLOR.silver,
    error: COLOR.redWildWatermelon,
    errorSecondary: COLOR.persianRed,
    deletionStatus: COLOR.linkWater,
    tooltip: COLOR.stormGray,
    disabled: COLOR.ghost,
    warningAlert: COLOR.lightYellow,
    warningAlertIcon: COLOR.yellow2,
    successAlert: COLOR.greenGrass,
    errorAlert: COLOR.lightPersianRed,
    infoAlert: COLOR.lightSkyBlue,
    infoAlertIcon: COLOR.lightNavyBlue,
};

export const FONT_SIZE = {
    smallest: '10px',
    smaller: '11px',
    small: '12px',
    slmedium: '13px',
    smedium: '14px',
    ssmedium: '15px',
    mediumpx: '16px',
    medium: '1rem',
    lmedium: '18px',
    llmedium: '19px',
    xlmedium: '20px',
    xsmedium: '21px',
    xmedium: '24px',
    large: '1.5rem',
    xlarge: '2rem',
    xslarge: '2.8rem',
    xxlarge: '3.2rem',
    xxlargepx: '32px',
    xxxlarge: '48px',
};

export const FONT_WEIGHT = {
    lite: '300',
    normal: '400',
    medium: '500',
    bold: '700',
};

export const LINE_HEIGHT = {
    small: '10px',
    normal: '17px',
    large: '30px',
};

export const BORDER_RADIUS = {
    small: '4px',
    medium: '6px',
    normal: '8px',
    button: '40px',
    chip: '4px',
    round: '50%',
};

export const BORDER_WIDTH = {
    normal: '1px',
    medium: '1.5px',
};

export const GLOBAL_MEDIA_QUERIES = {
    xxsmobile: '(max-width: 360px)',
    xsmobile: '(min-width: 360px)',
    xssmobile: '(min-width: 375px)',
    ssmobile: '(min-width: 450px)',
    smobile: '(min-width: 600px)',
    mobile: '(min-width: 768px)',
    tablet: '(min-width: 992px)',
    ltablet: '(min-width: 1080px)',
    laptop: '(min-width: 1200px)',
    llaptop: '(min-width: 1366px)',
    desktop: '(min-width: 1600px)',
    ldesktop: '(min-width: 1920px)',
};

export const ICON_SIZE = {
    small: '1rem',
    medium: '2rem',
    large: '3rem',
};

export const PX_TO_REM = {
    1: rem(1),
    2: rem(2),
    3: rem(3),
    4: rem(4),
    5: rem(5),
    6: rem(6),
    7: rem(7),
    8: rem(8),
    9: rem(9),
    10: rem(10),
    11: rem(11),
    12: rem(12),
    13: rem(13),
    14: rem(14),
    15: rem(15),
    16: rem(16),
    17: rem(17),
    18: rem(18),
    19: rem(19),
    20: rem(20),
    21: rem(21),
    22: rem(22),
    23: rem(23),
    24: rem(24),
    25: rem(25),
    26: rem(26),
    27: rem(27),
    28: rem(28),
    29: rem(29),
    30: rem(30),
    31: rem(31),
    32: rem(32),
    33: rem(33),
    34: rem(34),
    35: rem(35),
    36: rem(36),
    37: rem(37),
    38: rem(38),
    39: rem(39),
    40: rem(40),
    41: rem(41),
    42: rem(42),
    43: rem(43),
    44: rem(44),
};

export const INPUTS_WIDTH = {
    XS: '6rem',
    S: '12rem',
    M: '24rem',
};

export const customScrollbar = css`
    ::-webkit-scrollbar {
        height: 6px;
        width: 6px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${COLOR.pattensBlue};
        border-radius: ${BORDER_RADIUS.normal};
    }

    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: ${COLOR.pattensBlue} transparent;
`;
