import { globalState } from '@/components/Global/GlobalDataManagement';
import { getFetchOptions, getFetchOptionsWithAuth } from '@/dataExchange/utils/fetchOptions';
import {
    AllInvitationsResponseDto,
    ForgetPasswordParamsDto,
    GetAccessTokenParamsDto,
    GetAccessTokenResponseDto,
    InviteEmployeeParamsDto,
    RegisterOrganizationAndUserParamsDto,
    RegisterOrganizationParamsDto,
    ResetPasswordParamsDto,
    RevokeAccessTokenParamsDto,
    SendOrganizationAccountActivationParamsDto,
    SendUserAccountActivationParamsDto,
} from '@/domain/auth';
import { CONTENT_TYPE, HTTP_METHOD } from '@/utils/enums/dataExchange';

export const getAccessToken = async (params: GetAccessTokenParamsDto): Promise<GetAccessTokenResponseDto> => {
    const data = {
        password: params.password,
        username: params.username,
        client_id: params.client_id ?? '1',
        grant_type: params.grant_type ?? 'password',
    };
    const response = await fetch(`${globalState.config.FQ_BASE_API_URL}/oauth/token`, {
        ...getFetchOptions({ addAuth: false, method: HTTP_METHOD.POST, contentType: CONTENT_TYPE.FORM }),
        body: new URLSearchParams(data),
    });
    const responseBody = await response.json();
    if (!response.ok) {
        throw responseBody;
    }
    return responseBody;
};

export const revokeAccessToken = async (params: RevokeAccessTokenParamsDto): Promise<void> => {
    await fetch(`${globalState.config.FQ_BASE_API_URL}/oauth/logout`, {
        ...getFetchOptionsWithAuth({ method: HTTP_METHOD.POST }),
        mode: 'cors',
        redirect: 'follow',
        body: JSON.stringify({
            grant_type: params.grant_type,
        }),
    });
};

export const forgetPassword = async ({ email }: ForgetPasswordParamsDto) => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/credential/forget`,
        getFetchOptionsWithAuth({
            method: HTTP_METHOD.POST,
            body: { email },
        })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }
    return response;
};

export const sendResetPassword = async ({
    token,
    password,
    passwordConfirmation,
}: ResetPasswordParamsDto): Promise<null> => {
    const params = {
        token,
        password,
        passwordConfirmation,
    };
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/credential/reset-password`,
        getFetchOptions({ addAuth: false, method: HTTP_METHOD.POST, body: params })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return null;
};

export const registerOrganization = async (params: RegisterOrganizationParamsDto) => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/account/register`,
        getFetchOptionsWithAuth({ method: HTTP_METHOD.POST, body: params })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }
};

export const registerOrganizationAndUser = async (
    params: RegisterOrganizationAndUserParamsDto
): Promise<GetAccessTokenResponseDto> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/account/register/organization`,
        getFetchOptionsWithAuth({ method: HTTP_METHOD.POST, body: params })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const sendUserAccountActivation = async (params: SendUserAccountActivationParamsDto): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/credential-invitation/accept-invitation`,
        getFetchOptions({ addAuth: false, method: HTTP_METHOD.POST, body: params })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return null;
};

export const deleteUserAccountInvitation = async (token: string): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/credential-invitation/delete-invitation/${token}`,
        getFetchOptionsWithAuth({ method: HTTP_METHOD.DELETE })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return null;
};

export const inviteEmployee = async (params: InviteEmployeeParamsDto): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/credential-invitation/invite`,
        getFetchOptionsWithAuth({ method: HTTP_METHOD.POST, body: params })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return null;
};

export const resendInvitation = async (tokenId: number): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/credential-invitation/resend-invitation/${tokenId}`,
        getFetchOptionsWithAuth({ contentType: null, method: HTTP_METHOD.POST })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return null;
};

export const getAllInvitations = async (): Promise<AllInvitationsResponseDto[]> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/credential-invitation`,
        getFetchOptionsWithAuth({ contentType: null, method: HTTP_METHOD.GET })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return response.json();
};

export const sendOrganizationAccountActivation = async ({
    credentialId,
    activationKey,
}: SendOrganizationAccountActivationParamsDto): Promise<null> => {
    const response = await fetch(
        `${globalState.config.FQ_BASE_API_URL}/account/activate/${credentialId}/${activationKey}`,
        getFetchOptions({ addAuth: false, contentType: null, method: HTTP_METHOD.GET })
    );

    if (!response.ok) {
        const parsedResponse = await response.json();
        throw parsedResponse;
    }

    return null;
};
