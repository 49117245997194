import { memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { DEFAULT_GUEST_ROUTE } from '@/pages/Routing/Routing';
import { ReactComponent as FreeQuestLogoSVG } from '@/resources/logos/freequest-logo.svg';

const FreeQuestLogo = ({ className }: { className?: string }) => (
    <Link className={className} to={DEFAULT_GUEST_ROUTE}>
        <FreeQuestLogoSVG />
    </Link>
);

const Memoized = memo(FreeQuestLogo);
const Styled = styled(Memoized)``;

export default Styled;
