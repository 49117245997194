import { ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { FQ_BUTTON_VARIANTS } from '@/components/muiCustomized/FQButton/constant';
import FQButton from '@/components/muiCustomized/FQButton/FQButton';
import { BORDER_RADIUS, COLOR, GLOBAL_MEDIA_QUERIES } from '@/globalStyles';
import { DEFAULT_GUEST_ROUTE } from '@/pages/Routing/Routing';

import ErrorContent from './ErrorContent';
import SuccessContent from './SuccessContent';
import { VariantStatus } from './type';

type StripeStatusProps = {
    variant: VariantStatus;
};

type StyledButtonProps = {
    mobile: boolean;
} & ButtonProps;

const StripeStatus = ({ variant }: StripeStatusProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
    const mobile = !matches.ltablet;

    return (
        <Container>
            {variant === VariantStatus.success && <SuccessContent />}
            {variant === VariantStatus.error && <ErrorContent />}
            <Wrapper>
                <StyledButton
                    onClick={() => {
                        localStorage.clear();
                        navigate(DEFAULT_GUEST_ROUTE);
                    }}
                    color="fqRed"
                    data-variant={FQ_BUTTON_VARIANTS.fqPrimary}
                    data-testid="redirect-button"
                    fullWidth
                    mobile={mobile}
                >
                    {variant === VariantStatus.success
                        ? t('stripe_status_back_to_app')
                        : t('administratorPanel_tab_subscription_tryLater')}
                </StyledButton>
            </Wrapper>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 390px;
    padding-bottom: 10rem;
    background-color: ${COLOR.white};
`;

const Wrapper = styled.div`
    margin-top: 1.5rem;
    width: 329px;
`;

const StyledButton = styled(({ mobile, ...rest }: StyledButtonProps) => <FQButton {...rest} />)<StyledButtonProps>`
    ${({ mobile }) => `border-radius: ${mobile && BORDER_RADIUS.medium} !important`}
`;

export default StripeStatus;
