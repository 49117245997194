import { memo } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';

import useLoggedInUserData from '@/hooks/useUserData/useLoggedInUserData';

type ActivatedRedirectRouteProps = {
    children: JSX.Element;
    path: string;
};

const ActivatedRedirectRoute = ({ children, path }: ActivatedRedirectRouteProps) => {
    const { activated } = useLoggedInUserData();

    return activated ? <Navigate to={path} /> : children;
};

const Memoized = memo(ActivatedRedirectRoute);
const Styled = styled(Memoized)``;

export default Styled;
