import ErrorIcon from '@mui/icons-material/Error';
import { OptionsObject, SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import { createRef, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { COLOR, COLOR_FUNCTION, FONT_SIZE } from '@/globalStyles';

import { CloseAction } from './CustomAlertAction';

export enum ALERT_VARIANT {
    default = 'default',
    error = 'error',
    success = 'success',
    warning = 'warning',
    info = 'info',
}

const DEFAULT_OPTIONS: OptionsObject = { preventDuplicate: true };

export const useAlertMessages = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const defaultMessages = {
        [ALERT_VARIANT.success]: t('translation_default_alerts_success'),
        [ALERT_VARIANT.error]: t('error_unknownError_message'),
    };

    const createAlert = (variant: ALERT_VARIANT, message?: React.ReactNode, options?: OptionsObject) => {
        const alertMessage = message ?? defaultMessages[variant];
        if (!alertMessage) {
            throw new Error('Default message should only be used for success or error alert messages');
        }

        const opts = { ...DEFAULT_OPTIONS, ...options };

        const alertId = enqueueSnackbar(alertMessage, { variant, ...opts });
        return alertId;
    };

    const removeAlert = useCallback(
        (id: SnackbarKey | undefined) => {
            closeSnackbar(id);
        },
        [closeSnackbar]
    );

    const cleanAllAlerts = useCallback(() => {
        closeSnackbar();
    }, [closeSnackbar]);

    return {
        createAlert,
        removeAlert,
        cleanAllAlerts,
    };
};

const MAX_ALERTS_AT_TIME = 5;
const AUTO_HIDE_TIMEOUT = 5000;

const AlertMessagesProvider = ({ className, children }: { className?: string; children: ReactNode }) => {
    const snackbarProviderRef = createRef<SnackbarProvider>();

    const onClickDismiss = key => () => {
        snackbarProviderRef?.current?.closeSnackbar(key);
    };

    return (
        <SnackbarProvider
            className={className}
            ref={snackbarProviderRef}
            maxSnack={MAX_ALERTS_AT_TIME}
            action={key => <CloseAction onClick={onClickDismiss(key)} />}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            autoHideDuration={AUTO_HIDE_TIMEOUT}
            iconVariant={{
                ...snackbarProviderRef.current?.props.iconVariant,
                warning: (
                    <WarningIcon
                        sx={{
                            color: COLOR_FUNCTION.warningAlertIcon,
                            // snackbar default icon styles
                            fontSize: FONT_SIZE.xlmedium,
                            marginInlineEnd: '8px',
                        }}
                    />
                ),
            }}
        >
            {children}
        </SnackbarProvider>
    );
};

const WarningIcon = styled(ErrorIcon)``;

const Styled = styled(AlertMessagesProvider)`
    width: 350px;

    .SnackbarItem-message {
        flex: 1 1 215px;
    }

    &&.SnackbarItem-variantSuccess {
        background-color: ${COLOR_FUNCTION.successAlert};
        color: ${COLOR.dirtyWhite2};

        > div:first-child svg {
            color: ${COLOR.white};
        }
    }
    &&.SnackbarItem-variantError {
        background-color: ${COLOR_FUNCTION.errorAlert};
        color: ${COLOR.dirtyWhite2};

        > div:first-child svg {
            color: ${COLOR.white};
        }
    }
    &&.SnackbarItem-variantWarning {
        background-color: ${COLOR_FUNCTION.warningAlert};
        color: ${COLOR.veryDarkGrayishRed};
    }
    &&.SnackbarItem-variantInfo {
        background-color: ${COLOR_FUNCTION.infoAlert};
        color: ${COLOR.veryDarkGrayishRed};

        > div:first-child svg {
            color: ${COLOR_FUNCTION.infoAlertIcon};
        }
    }
`;
export default Styled;
