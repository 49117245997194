import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { transparentize } from 'polished';

import {
    FQ_BUTTON_BOX_SHADOW,
    FQ_BUTTON_COLOR_MAP,
    FQ_BUTTON_VARIANTS,
} from '@/components/muiCustomized/FQButton/constant';
import { FQ_BUTTON_PALETTE_COLOR } from '@/components/muiCustomized/FQButton/types';
import { BORDER_RADIUS, COLOR, PX_TO_REM } from '@/globalStyles';

const FQButton = styled(Button, { shouldForwardProp: props => props !== 'data-variant' })(
    ({ theme, color = 'primary', disableRipple, ...props }) => {
        const variant = props['data-variant'];
        const paletteColor = FQ_BUTTON_COLOR_MAP[variant]?.[color] as FQ_BUTTON_PALETTE_COLOR;

        if (!variant || !paletteColor) return {};

        const { textColor, textDisabledColor, backgroundColor } = paletteColor;

        switch (variant) {
            case FQ_BUTTON_VARIANTS.fqPrimary:
                return {
                    height: PX_TO_REM[40],
                    padding: '0.5rem 1.5rem',
                    borderRadius: BORDER_RADIUS.button,
                    backgroundColor: transparentize(0.1, backgroundColor),
                    border: '1px solid transparent',
                    color: textColor,
                    cursor: 'pointer',
                    textTransform: 'none',
                    '&:hover': {
                        boxShadow: FQ_BUTTON_BOX_SHADOW,
                        backgroundColor: backgroundColor,
                    },
                    '&:disabled': {
                        backgroundColor: transparentize(0.5, backgroundColor),
                        color: textDisabledColor,
                        cursor: 'not-allowed',
                    },
                };
            case FQ_BUTTON_VARIANTS.fqOutline:
                return {
                    height: PX_TO_REM[40],
                    padding: '0.5rem 1.5rem',
                    borderRadius: BORDER_RADIUS.button,
                    backgroundColor: COLOR.white,
                    border: '1px solid',
                    color: textColor,
                    cursor: 'pointer',
                    textTransform: 'none',
                    '&:hover': {
                        boxShadow: FQ_BUTTON_BOX_SHADOW,
                        backgroundColor: transparentize(0.95, backgroundColor),
                    },
                    '&:disabled': {
                        backgroundColor: transparentize(0.95, backgroundColor),
                        borderColor: transparentize(0.5, textDisabledColor),
                        color: transparentize(0.5, textDisabledColor),
                        cursor: 'not-allowed',
                    },
                };
            case FQ_BUTTON_VARIANTS.fqText:
                return {
                    height: PX_TO_REM[40],
                    padding: '0.5rem 0.75rem',
                    borderRadius: BORDER_RADIUS.button,
                    color: textColor,
                    textTransform: 'none',
                    '&:hover': {
                        boxShadow: FQ_BUTTON_BOX_SHADOW,
                        backgroundColor: transparentize(0.95, backgroundColor),
                    },
                    '&:disabled': {
                        color: transparentize(0.5, textDisabledColor),
                        cursor: 'not-allowed',
                    },
                };
            default:
                return {};
        }
    }
);

export default FQButton;
