export enum PERIOD {
    YEAR = 'YEAR',
    MONTH = 'MONTH',
    WEEK = 'WEEK',
    SINGLE_USE = 'SINGLE_USE',
}

export const PERIOD_DESCRIPTIONS = {
    [PERIOD.YEAR]: 'absenceTypeLimitsView_yearly_title',
    [PERIOD.MONTH]: 'absenceTypeLimitsView_monthly_title',
    [PERIOD.WEEK]: 'absenceTypelimitsView_weekly_title',
    [PERIOD.SINGLE_USE]: 'absenceTypelimitsView_singleUse_title',
};

export const PERIOD_LIMITS_DESCRIPTIONS = {
    [PERIOD.YEAR]: 'translation_this_year',
    [PERIOD.MONTH]: 'translation_this_month',
    [PERIOD.WEEK]: 'translation_this_week',
    [PERIOD.SINGLE_USE]: 'absenceTypelimitsView_singleUse_title',
};

export enum ACCOUNT_ROLE {
    ROLE_USER = 'ROLE_USER',
    ROLE_COMPANY_OWNER = 'ROLE_COMPANY_OWNER',
    ROLE_ADMINISTRATOR = 'ROLE_ADMINISTRATOR',
    ROLE_MANAGER = 'ROLE_MANAGER',
}

export enum USER_STATUS {
    ALL = 'ALL',
    ACTIVE = 'ACTIVE',
    BANNED = 'BANNED',
}

export enum ORDER {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum ORDER_BY {
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',
    FROM = 'from',
    FIRST_NAME = 'firstName',
}

export enum DAYS {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
}
export enum RECURRING_TYPE {
    NONE = 'NONE',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
}

export enum DAYOFF_MODAL_ACTION {
    CREATE = 'CREATE',
    DELETE = 'DELETE',
}

export enum TOGGLE_BAN_ACTION {
    BAN = 'BAN',
    UNBAN = 'UNBAN',
}
