import { SUBSCRIPTION_NAME } from '@/utils/enums/subscription';

export const EUR_COUNTRIES = [
    'AT',
    'BE',
    'CY',
    'EE',
    'FI',
    'FR',
    'GR',
    'ES',
    'NL',
    'IE',
    'LT',
    'LU',
    'LV',
    'MT',
    'DE',
    'PT',
    'SK',
    'SI',
    'IT',
    'AD',
    'MC',
    'SM',
    'VA',
];
export const MAX_ENTERPRISE_USERS = 999;
export const PLN_COUNTRY = 'PL';
export const ENTERPRISE_PLAN_EMPLOYEES = '> 50';
export const DAYS_IN_MONTH = 31;
export const NEXT_PAYMENT = 30;
export const TWO_WEEKS_BEFORE_NEXT_PAYMENT = 16;
export const DAY_BEFORE_NEXT_PAYMENT = 29;
export const NUMBER_USERS_EXPIRED_SUBSCRIPTION = 0;
export const DEFAULT_PRICE_PLAN = {
    _id: '1',
    name: SUBSCRIPTION_NAME.Startup,
    iconFilename: 'leaf.svg',
    description: 'Description',
    maxUsers: 9,
    metadata: {
        max_users: '9',
    },
    prices: [
        {
            isDefaultPrice: true,
            stripePriceId: '',
            interval: 'month',
            currencies: {
                eur: 0,
                pln: 0,
                usd: 0,
            },
        },
        {
            isDefaultPrice: false,
            stripePriceId: '',
            interval: 'year',
            currencies: {
                eur: 0,
                pln: 0,
                usd: 0,
            },
        },
    ],
};
