export const parseResponseImage = async (response: Response) => {
    const blob = await response.blob();
    const reader = new FileReader();

    reader.readAsDataURL(blob);

    const image: FileReader = await new Promise(resolve => {
        reader.addEventListener('loadend', async () => {
            resolve(reader);
        });
    });

    return image?.result;
};
