import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { COLOR, FONT_SIZE, GLOBAL_MEDIA_QUERIES } from '@/globalStyles';
import { LINK } from '@/pages/Routing/routesAndLinks';
import AppStoreDE from '@/resources/marketplaceButtons/appstore-de.svg';
import AppStoreEN from '@/resources/marketplaceButtons/appstore-en.svg';
import AppStorePL from '@/resources/marketplaceButtons/appstore-pl.svg';
import GoogleStoreDE from '@/resources/marketplaceButtons/google-icon-de.svg';
import GoogleStoreEN from '@/resources/marketplaceButtons/google-icon-en.svg';
import GoogleStorePL from '@/resources/marketplaceButtons/google-icon-pl.svg';
import { formatUnicorn } from '@/utils/string';

interface IAuthFooterProps {
    className?: string;
    addEnjoyText?: boolean;
}

const defaultMultiplier = 8;

export const AuthFooter = ({ className, addEnjoyText }: IAuthFooterProps) => {
    const { t } = useTranslation();

    return (
        <div className={className}>
            <DownloadWrapper>
                <DownloadAppTextWrapper>
                    <DownloadAppText>
                        {t('auth_common_download_app')}
                        {addEnjoyText && <>&nbsp;{t('auth_common_download_enjoy')}</>}
                    </DownloadAppText>
                </DownloadAppTextWrapper>
                <DownloadMobileAppBadges />
            </DownloadWrapper>
        </div>
    );
};

export const DownloadMobileAppBadges = styled(({ className }) => {
    const { i18n } = useTranslation();

    return (
        <div className={className}>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={formatUnicorn(LINK.APP_APPLE_STORE, {
                    lang: navigator.language,
                })}
            >
                <img alt="ios-store-icon" src={AppStoreBadge[i18n.resolvedLanguage]} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={LINK.APP_GOOGLE_STORE}>
                <img alt="google-store-icon" src={GoogleStoreBadge[i18n.resolvedLanguage]} />
            </a>
        </div>
    );
})`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    a {
        &:first-of-type {
            margin-right: 0.5rem;
        }
        img {
            height: 2.6rem;
        }
    }
`;

const DownloadWrapper = styled.div``;
const DownloadAppTextWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const DownloadAppText = styled.div`
    font-size: ${FONT_SIZE.slmedium};
    line-height: ${FONT_SIZE.xsmedium};
    letter-spacing: 0.6px;
    text-align: center;
    color: ${COLOR.blackPearl};
    width: 60%;
`;
const AppStoreBadge: { [key: string]: string } = {
    en: AppStoreEN,
    pl: AppStorePL,
    de: AppStoreDE,
};

const GoogleStoreBadge: { [key: string]: string } = {
    en: GoogleStoreEN,
    pl: GoogleStorePL,
    de: GoogleStoreDE,
};

const Memoized = memo(AuthFooter);
const Styled = styled(Memoized)<{ multiplier?: number }>`
    --padding-unit: 0.6rem;

    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    ${DownloadWrapper} {
        margin-top: calc(${props => props.multiplier ?? defaultMultiplier} * var(--padding-unit));
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    ${DownloadAppTextWrapper} {
        text-align: center;
        margin-bottom: var(--padding-unit);
        @media screen and ${GLOBAL_MEDIA_QUERIES.ltablet} {
            width: 60%;
        }

    ${DownloadAppText} {
        @media screen and ${GLOBAL_MEDIA_QUERIES.ltablet} {
            width: 100%;
        }
    }
`;
export default Styled;
