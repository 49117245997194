import React, { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import FullScreenLoader from '@/components/common/Loaders/FullScreenLoader';
import AuthLayout from '@/components/Global/layouts/AuthLayout/AuthLayout';
import AuthLayoutCenter from '@/components/Global/layouts/AuthLayout/AuthLayoutCenter';
import useAuthController from '@/pages/Auth/useAuthController';
import GuestRoute from '@/pages/Routing/GuestRoute/GuestRoute';

interface IAuthProps {
    className?: string;
}

const Login = React.lazy(() => import(/* webpackChunkName: "Login" */ '@/pages/Auth/Login/Login'));
const Register = React.lazy(() => import(/* webpackChunkName: "Register" */ '@/pages/Auth/Register/Register'));
const OrganizationActivateAccountSuccess = React.lazy(
    () =>
        import(
            /* webpackChunkName: "OrganizationActivateAccountSuccess" */ '@/pages/Auth/OrganizationActivateAccount/OrganizationActivateAccountSuccess'
        )
);
const OrganizationActivateAccountInvalidToken = React.lazy(
    () =>
        import(
            /* webpackChunkName: "OrganizationActivateAccountInvalidToken" */ '@/pages/Auth/OrganizationActivateAccount/OrganizationActivateAccountInvalidToken'
        )
);
const UserActivateAccount = React.lazy(
    () => import(/* webpackChunkName: "UserActivateAccount" */ '@/pages/Auth/UserActivateAccount/UserActivateAccount')
);
const UserActivateAccountInvalidToken = React.lazy(
    () =>
        import(
            /* webpackChunkName: "UserActivateAccountInvalidToken" */ '@/pages/Auth/UserActivateAccount/UserActivateAccountInvalidToken'
        )
);
const ResetPasswordFirstStep = React.lazy(
    () =>
        import(
            /* webpackChunkName: "ResetPasswordFirstStep" */ '@/pages/Auth/ResetPassword/ResetPasswordFirstStep/ResetPasswordFirstStep'
        )
);
const ResetPasswordSecondStep = React.lazy(
    () =>
        import(
            /* webpackChunkName: "ResetPasswordSecondStep" */ '@/pages/Auth/ResetPassword/ResetPasswordSecondStep/ResetPasswordSecondStep'
        )
);

const ResetPasswordInvalidToken = React.lazy(
    () =>
        import(
            /* webpackChunkName: "ResetPasswordInvalidToken" */ '@/pages/Auth/ResetPassword/ResetPasswordInvalidToken'
        )
);

export enum AUTH_ROUTES {
    LOGIN = '/login',
    ORGANIZATION_ACTIVATE_ACCOUNT_SUCCESS = '/organization-activate-account/success',
    ORGANIZATION_ACTIVATE_ACCOUNT_INVALID_TOKEN = '/organization-activate-account/invalid-token',
    USER_ACTIVATE_ACCOUNT = '/user-activate-account',
    USER_ACTIVATE_ACCOUNT_INVALID_TOKEN = '/user-activate-account/invalid-token',
    RESET_PASSWORD_FIRST_STEP = '/reset-password-first-step',
    RESET_PASSWORD_SECOND_STEP = '/reset-password-second-step',
    RESET_PASSWORD_INVALID_TOKEN = '/reset-password-invalid-token',
    REGISTER = '/register',
    REGISTER_ORGANIZATION_AND_USER = '/register-organization-and-user',
    FORGET_PASSWORD = '/forget-password',
    STRIPE_STATUS = '/stripe-status',
}

export type AuthController = ReturnType<typeof useAuthController> | null;
export const AuthContext = React.createContext<AuthController>(null);

const Auth = ({ className }: IAuthProps) => {
    const AuthController = useAuthController();
    return (
        <div className={className}>
            {AuthController.isLoading ? <FullScreenLoader /> : null}
            <AuthContext.Provider value={AuthController}>
                <Routes>
                    <Route path={AUTH_ROUTES.REGISTER} element={<Register />} />
                    <Route
                        path={AUTH_ROUTES.ORGANIZATION_ACTIVATE_ACCOUNT_SUCCESS}
                        element={<OrganizationActivateAccountSuccess />}
                    />
                    <Route
                        path={AUTH_ROUTES.ORGANIZATION_ACTIVATE_ACCOUNT_INVALID_TOKEN}
                        element={<OrganizationActivateAccountInvalidToken />}
                    />
                    <Route
                        path={AUTH_ROUTES.LOGIN}
                        element={
                            <AuthLayout>
                                <GuestRoute>
                                    <Login />
                                </GuestRoute>
                            </AuthLayout>
                        }
                    />
                    <Route
                        path={AUTH_ROUTES.USER_ACTIVATE_ACCOUNT}
                        element={
                            <AuthLayout>
                                <UserActivateAccount />
                            </AuthLayout>
                        }
                    />
                    <Route
                        path={AUTH_ROUTES.USER_ACTIVATE_ACCOUNT_INVALID_TOKEN}
                        element={
                            <AuthLayout>
                                <UserActivateAccountInvalidToken />
                            </AuthLayout>
                        }
                    />
                    <Route
                        path={AUTH_ROUTES.RESET_PASSWORD_FIRST_STEP}
                        element={
                            <AuthLayout>
                                <GuestRoute>
                                    <ResetPasswordFirstStep />
                                </GuestRoute>
                            </AuthLayout>
                        }
                    />
                    <Route
                        path={AUTH_ROUTES.RESET_PASSWORD_SECOND_STEP}
                        element={
                            <AuthLayout>
                                <ResetPasswordSecondStep />
                            </AuthLayout>
                        }
                    />
                    <Route
                        path={AUTH_ROUTES.RESET_PASSWORD_INVALID_TOKEN}
                        element={
                            <AuthLayout>
                                <ResetPasswordInvalidToken />
                            </AuthLayout>
                        }
                    />
                </Routes>
            </AuthContext.Provider>
        </div>
    );
};

const Memoized = memo(Auth);
const Styled = styled(Memoized)`
    & .MuiGrid-root {
        min-height: 100vh;
    }
`;
export default Styled;
