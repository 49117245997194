import * as Sentry from '@sentry/react';

import { FQ_NODE_ENV } from '@/utils/constants/common';

export function sentryTracking(error: unknown, sentryAction?: SENTRY_ACTIONS, userId?: string) {
    if (FQ_NODE_ENV === undefined) {
        return;
    }

    Sentry.withScope(scope => {
        scope.setTag('Error:', sentryAction);
        if (userId) {
            scope.setUser({ userId });
        }
        Sentry.captureException(error);
    });
}

export enum SENTRY_ACTIONS {
    ERROR_GET_USER_PROFILE = 'ErrorGetUserProfile',
    ERROR_GET_USER_PICTURE = 'ErrorGetUserPicture',
    ERROR_GET_INITIAL_CONFIG = 'ErrorGetInitialConfig',
    ERROR_UPDATE_NOTIFICATIONS_AS_SEEN = 'ErrorUpdateNotificationsAsSeen',
    ERROR_TOGGLE_USER_BAN = 'ErrorToggleUserBan',
    ERROR_GET_ALL_USERS = 'ErrorGetAllUsers',
    ERROR_GET_ALL_TEAMS = 'ErrorGetAllTeams',
    ERROR_GET_STORE_HOLIDAYS = 'ErrorGetStoreHolidays',
    ERROR_GET_AVAILABLE_COUNTRIES_DAYS = 'ErrorGetAvailableCountriesDays',
    ERROR_CREATE_DAY_OFF = 'ErrorCreateDayOff',
    ERROR_DELETE_DAY_OFF = 'ErrorDeleteDayOff',
    ERROR_GET_ORGANIZATION_MEMBERS = 'ErrorGetOrganizationMembers',
    ERROR_DELETE_INVITATION = 'ErrorDeleteInvitation',
    ERROR_RESEND_INVITATION = 'ErrorResendInvitation',
    ERROR_GET_ALL_INVITATIONS = 'ErrorGetAllInvitations',
    ERROR_SEND_INVITATION = 'ErrorSendInvitation',
    ERROR_GET_ALL_APPROVERS_IN_ORGANIZATION = 'ErrorGetAllApproversInOrganization',
    ERROR_GET_ABSENCE_TYPES_DATA_REQUEST_NEW = 'ErrorGetAbsenceTypesDataRequestNew',
    ERROR_DELETE_ABSENCE_TYPE_REQUEST = 'ErrorDeleteAbsenceTypeRequest',
    ERROR_CREATE_ABSENCE_TYPE_LIMIT_REQUEST = 'ErrorCreateAbsenceTypeLimitRequest',
    ERROR_UPDATE_ABSENCE_TYPE_LIMIT_REQUEST = 'ErrorUPDATEAbsenceTypeLimitRequest',
    ERROR_UPDATE_ABSENCE_TYPE_REQUEST = 'ErrorUpdateAbsenceTypeRequest',
    ERROR_CREATE_ABSENCE_TYPE_REQUEST = 'ErrorCreateAbsenceTypeRequest',
    ERROR_GET_ABSENCE_GLOBAL_LIMITS = 'ErrorGetAbsenceGlobalLimits',
    ERROR_PERSONAL_LIMIT_RESTORE_DEFAULTS = 'ErrorPersonalLimitRestoreDefaults',
    ERROR_SAVE_PERSONAL_LIMIT = 'ErrorSavePersonalLimit',
    ERROR_GET_USER_LIMITS_SUMMARY = 'ErrorGetUserLimitsSummary',
    ERROR_GET_USER_LIMITS = 'ErrorGetUserLimits',
    ERROR_GET_USER_LIMIT_RESTRICTION = 'ErrorGetUserLimitRestriction',
    ERROR_GET_USER_LIMITS_USAGE = 'ErrorGetUserLimitsUsage',
    ERROR_GET_ABSENCE_LIMIT_RESTRICTION_GLOBAL = 'ErrorGetAbsenceLimitRestrictionGlobal',
    ERROR_GET_ORGANIZATION_DATA = 'ErrorGetOrganizationData',
    ERROR_UPDATE_ORGANIZATION_DATA = 'ErrorUpdateOrganizationData',
    ERROR_GET_TAX_COUNTRIES = 'ErrorGetTaxCountries',
    ERROR_GET_CUSTOMER_PORTAL_URL_SUB_MODAL = 'ErrorGetCustomerPortalUrlSubModal',
    ERROR_GET_CUSTOMER_PORTAL_URL_SUB_DETAILS = 'ErrorGetCustomerPortalUrlSubDetails',
    ERROR_GET_CUSTOMER_PORTAL_URL = 'ErrorGetCustomerPortalUrl',
    ERROR_CREATE_CHECKOUT_SESSION_SUB = 'ErrorCreateCheckoutSessionSub',
    ERROR_GET_SUBSCRIPTIONS_PLANS = 'ErrorGetSubscriptionsPlans',
    ERROR_LOGIN = 'ErrorLogin',
    ERROR_REGISTER_ORGANIZATION = 'ErrorRegisterOrganization',
    ERROR_REGISTER_ORGANIZATION_AND_USER = 'ErrorRegisterOrganizationAndUser',
    ERROR_CHANGE_ABSENCE_STATUS = 'ErrorChangeAbsenceStatus',
    ERROR_GET_ALL_USERS_ABSENCES = 'ErrorGetAllUSersAbsences',
    ERROR_GET_USER_APPROVERS = 'ErrorGetUserApprovers',
    ERROR_GET_ALL_APPROVERS_DETAILS = 'ErrorGetAllApproversDetails',
    ERROR_GET_OWN_LIMITS_USAGE = 'ErrorGetOwnLimitsUsage',
    ERROR_CREATE_OWN_REQUEST = 'ErrorCreateOwnRequest',
    ERROR_CREATE_USER_REQUEST = 'ErrorCreateUserRequest',
    ERROR_GET_REQUEST_PREVIEW = 'ErrorGetRequestPreview',
    ERROR_GET_USER_DATA = 'ErrorGetUserData',
    ERROR_GET_USER_REQUESTS = 'ErrorGetUserRequests',
    ERROR_ADD_APPROVER_TO_USER = 'ErrorAddApproverToUser',
    ERROR_REMOVE_APPROVER_FROM_USER = 'ErrorRemoveApproverFromUser',
    ERROR_ADD_REMOVE_USER_ROLE = 'ErrorAddRemoveUserRole',
    ERROR_GET_USER_TEAMS = 'ErrorGetUserTeams',
    ERROR_ADD_USER_TO_TEAM = 'ErrorAddUserToTeam',
    ERROR_REMOVE_USER_FROM_TEAM = 'ErrorRemoveUserFromTeam',
    ERROR_UPLOAD_PROFILE_PICTURE = 'ErrorUploadProfilePicture',
    ERROR_UPDATE_OWN_NAMES = 'ErrorUpdateOwnNames',
    ERROR_GET_USER_DATA_PROFILE = 'ErrorGetUSerDataProfile',
    ERROR_UPDATE_REQUEST_DATA = 'ErrorUpdateRequestData',
    ERROR_GET_ALL_REQUESTS_TYPE = 'ErrorGetAllRequestsType',
    ERROR_GET_ALL_REQUESTS = 'ErrorGetAllRequests',
    ERROR_UPDATE_ABSENCE_STATUS = 'ErrorUpdateAbsenceStatus',
    ERROR_SEND_DELETE_ORGANIZATION_CODE = 'ErrorSendDeleteOrganizationCode',
    ERROR_DELETE_ORGANIZATION = 'ErrorDeleteOrganization',
    ERROR_UPDATE_NOTIFICATION_SETTINGS = 'ErrorUpdateNotificationSettings',
    ERROR_MARKETING_SETTINGS = 'ErrorMarketingSettings',
}
