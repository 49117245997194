import { useState } from 'react';

const useAuthController = () => {
    const [isLoading, setIsLoading] = useState(false);

    return {
        isLoading,
        setIsLoading,
    };
};

export default useAuthController;
