import React, { memo, Suspense } from 'react';
import styled from 'styled-components';

import FullScreenLoader from '@/components/common/Loaders/FullScreenLoader';

interface IGlobalSuspenseProps {
    className?: string;
    children?: React.ReactNode;
}

const GlobalSuspense = ({ children }: IGlobalSuspenseProps) => {
    return <Suspense fallback={<FullScreenLoader />}>{children}</Suspense>;
};

const Memoized = memo(GlobalSuspense);
const Styled = styled(Memoized)``;
export default Styled;
