import { memo, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';

import * as OAuth2Manager from '@/dataExchange/OAuth2Manager';
import useLoggedInUserData from '@/hooks/useUserData/useLoggedInUserData';
import { DEFAULT_GUEST_ROUTE, ROUTES } from '@/pages/Routing/Routing';

type IProtectedRouteProps = {
    children: JSX.Element;
    isAdminRoute?: boolean;
    onReject?(): void;
};

const ProtectedRoute = ({ isAdminRoute, children, onReject }: IProtectedRouteProps) => {
    const { activated, isAdmin } = useLoggedInUserData();
    const [target, setTarget] = useState<JSX.Element>();

    useEffect(() => {
        if (activated === false) {
            OAuth2Manager.logout();
        }

        if (OAuth2Manager.isLoggedIn() === false && OAuth2Manager.isRefreshTokenExisting() === false) {
            setTarget(<Navigate to={DEFAULT_GUEST_ROUTE} />);
        }

        if (!isAdmin && isAdminRoute) {
            onReject?.();
            setTarget(<Navigate to={ROUTES.DEFAULT_ROUTE} />);
        }
    }, [onReject, isAdmin, activated]);

    return target ?? children;
};

const Memoized = memo(ProtectedRoute);
const Styled = styled(Memoized)``;
export default Styled;
