import { Typography } from '@mui/material';
import { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';
import styled from 'styled-components';

import { DownloadMobileAppBadges } from '@/components/Global/layouts/AuthLayout/AuthFooter';
import AuthLayoutMobile from '@/components/Global/layouts/AuthLayout/AuthLayoutMobile';
import { COLOR, GLOBAL_MEDIA_QUERIES } from '@/globalStyles';

import AuthLayoutDesktop from './AuthLayoutDesktop';

export interface IAuthLayoutProps {
    className?: string;
    children?: ReactNode;
}

const AuthLayout = ({ className, children }: IAuthLayoutProps) => {
    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
    const Layout = matches.ltablet ? AuthLayoutDesktop : AuthLayoutMobile;

    return <Layout className={className}>{children}</Layout>;
};

export const MobileResolutionWarning = styled(({ className }) => {
    const { t } = useTranslation();
    return (
        <div className={className}>
            <Typography variant="mid_title">{t('translation_mobile_resolution_warning')}</Typography>
            <DownloadMobileAppBadges />
        </div>
    );
})`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    padding: 5rem;
    text-align: center;
    background-color: ${COLOR.aliceBlue};
    ${DownloadMobileAppBadges} {
        margin-top: 2rem;
        & a img {
            height: 3rem;
        }
    }
`;

const Memoized = memo(AuthLayout);
const Styled = styled(Memoized)``;

export default Styled;
