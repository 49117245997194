import React, { ReactNode } from 'react';

import ConfirmationDialog, { CONFIRMATION_VARIANT, ConfirmationOptions } from './ConfirmationDialog';

interface OpenDialogOptions {
    openDialog: (options: ConfirmationOptions) => void;
}

const ConfirmationDialogContext = React.createContext<OpenDialogOptions | null>(null);

export const ConfirmationDialogProvider = ({ children }: { children: ReactNode }) => {
    const [dialogConfig, setDialogConfig] = React.useState<ConfirmationOptions>();

    const openDialog = (options: ConfirmationOptions) => {
        setDialogConfig(options);
    };

    const onConfirm = () => {
        dialogConfig?.actions?.resolve();
        setDialogConfig(undefined);
    };

    const onDismiss = () => {
        if (dialogConfig?.catchOnCancel) {
            dialogConfig?.actions?.reject();
        }

        setDialogConfig(undefined);
    };

    return (
        <ConfirmationDialogContext.Provider value={{ openDialog }}>
            {children}
            <ConfirmationDialog
                open={Boolean(dialogConfig)}
                title={dialogConfig?.title || ''}
                message={dialogConfig?.message}
                onConfirm={onConfirm}
                onDismiss={onDismiss}
                variant={dialogConfig?.variant || CONFIRMATION_VARIANT.INFO}
            />
        </ConfirmationDialogContext.Provider>
    );
};

export const useConfirmationDialog = () => {
    const dialogOptions = React.useContext(ConfirmationDialogContext);
    const { openDialog } = dialogOptions ?? {};

    const getConfirmation = (options: ConfirmationOptions) =>
        new Promise<void>((resolve, reject) => {
            openDialog?.({ actions: { resolve, reject }, ...options });
        });

    return { getConfirmation };
};
