import { FQ_NODE_ENV, HOT_JAR_ID_DEV, HOT_JAR_ID_PROD, HOTJAR_SNIPPET_VERSION } from '@/utils/constants/common';

const selectEnvironment = () => {
    if (FQ_NODE_ENV === 'development') return HOT_JAR_ID_DEV;
    if (FQ_NODE_ENV === 'stage') return HOT_JAR_ID_DEV;
    if (FQ_NODE_ENV === 'production') return HOT_JAR_ID_PROD;

    return '';
};

const hotjarScript = document.createElement('script');
hotjarScript.innerHTML = `
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid: ${selectEnvironment()}, hjsv: ${HOTJAR_SNIPPET_VERSION}};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
`;

export default hotjarScript;
