export enum SUBSCRIPTION_STATUS {
    CANCELED = 'canceled',
    ACTIVE = 'active',
}

export enum SUBSCRIPTION_NAME {
    Startup = 'Startup',
    Growth = 'Growth',
    Business = 'Business',
    Enterprise = 'Enterprise',
}

export enum INTERVAL {
    MONTH = 'month',
    YEAR = 'year',
}
