import { FQ_BUTTON_PALETTE_COLOR } from '@/components/muiCustomized/FQButton/types';
import { COLOR } from '@/globalStyles';

export const FQ_BUTTON_BOX_SHADOW = '0px 1px 4px 0px #00000040';

export enum FQ_BUTTON_VARIANTS {
    fqPrimary = 'fqPrimary',
    fqOutline = 'fqOutline',
    fqText = 'fqText',
}

export enum FQ_BUTTON_COLOR_VARIANTS {
    fqGreen = 'fqGreen',
    fqRed = 'fqRed',
    fqBlue = 'fqBlue',
    fqYellow = 'fqYellow',
}

export const FQ_BUTTON_COLOR_MAP: Record<
    FQ_BUTTON_VARIANTS,
    Record<FQ_BUTTON_COLOR_VARIANTS, FQ_BUTTON_PALETTE_COLOR>
> = {
    [FQ_BUTTON_VARIANTS.fqPrimary]: {
        [FQ_BUTTON_COLOR_VARIANTS.fqGreen]: {
            textColor: COLOR.white,
            textDisabledColor: COLOR.white,
            backgroundColor: COLOR.mountainMeadow,
        },
        [FQ_BUTTON_COLOR_VARIANTS.fqRed]: {
            textColor: COLOR.white,
            textDisabledColor: COLOR.white,
            backgroundColor: COLOR.red,
        },
        [FQ_BUTTON_COLOR_VARIANTS.fqBlue]: {
            textColor: COLOR.white,
            textDisabledColor: COLOR.white,
            backgroundColor: COLOR.blue,
        },
        [FQ_BUTTON_COLOR_VARIANTS.fqYellow]: {
            textColor: COLOR.black,
            textDisabledColor: COLOR.suvaGrey,
            backgroundColor: COLOR.yellow,
        },
    },
    [FQ_BUTTON_VARIANTS.fqOutline]: {
        [FQ_BUTTON_COLOR_VARIANTS.fqGreen]: {
            textColor: COLOR.mountainMeadow,
            textDisabledColor: COLOR.mountainMeadow,
            backgroundColor: COLOR.white,
        },
        [FQ_BUTTON_COLOR_VARIANTS.fqRed]: {
            textColor: COLOR.red,
            textDisabledColor: COLOR.red,
            backgroundColor: COLOR.white,
        },
        [FQ_BUTTON_COLOR_VARIANTS.fqBlue]: {
            textColor: COLOR.blue,
            textDisabledColor: COLOR.blue,
            backgroundColor: COLOR.white,
        },
        [FQ_BUTTON_COLOR_VARIANTS.fqYellow]: {
            textColor: COLOR.yellow,
            textDisabledColor: COLOR.yellow,
            backgroundColor: COLOR.white,
        },
    },
    [FQ_BUTTON_VARIANTS.fqText]: {
        [FQ_BUTTON_COLOR_VARIANTS.fqGreen]: {
            textColor: COLOR.mountainMeadow,
            textDisabledColor: COLOR.mountainMeadow,
            backgroundColor: COLOR.white,
        },
        [FQ_BUTTON_COLOR_VARIANTS.fqRed]: {
            textColor: COLOR.red,
            textDisabledColor: COLOR.red,
            backgroundColor: COLOR.white,
        },
        [FQ_BUTTON_COLOR_VARIANTS.fqBlue]: {
            textColor: COLOR.blue,
            textDisabledColor: COLOR.blue,
            backgroundColor: COLOR.white,
        },
        [FQ_BUTTON_COLOR_VARIANTS.fqYellow]: {
            textColor: COLOR.yellow,
            textDisabledColor: COLOR.yellow,
            backgroundColor: COLOR.white,
        },
    },
};
