import { transparentize } from 'polished';
import { memo } from 'react';
import styled from 'styled-components';

import { COLOR } from '@/globalStyles';

import InlineLoader from './InlineLoader';

interface IFullScreenLoaderProps {
    className?: string;
}

const FullScreenLoader = ({ className }: IFullScreenLoaderProps) => {
    return (
        <div className={className}>
            <InlineLoader />
        </div>
    );
};

const Memoized = memo(FullScreenLoader);
const Styled = styled(Memoized)`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${() => transparentize(0.5, COLOR.platinum)};
`;
export default Styled;
