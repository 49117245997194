import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export enum CONFIRMATION_VARIANT {
    DANGER = 'DANGER',
    INFO = 'INFO',
}

export type ConfirmationOptions = {
    variant: CONFIRMATION_VARIANT;
    title: string;
    message?: string;
    catchOnCancel?: boolean;
    actions?: { resolve: () => void; reject: () => void };
};

type ConfirmationDialogProps = ConfirmationOptions & {
    open: boolean;
    onConfirm: () => void;
    onDismiss: () => void;
    className?: string;
};

const ConfirmationDialog = ({
    open,
    title,
    variant,
    message,
    onConfirm,
    onDismiss,
    className,
}: ConfirmationDialogProps) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} className={className}>
            <DialogTitle>{title}</DialogTitle>
            {message && (
                <DialogContent>
                    <DialogContentText>{message}</DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                {variant === CONFIRMATION_VARIANT.DANGER && (
                    <>
                        <Button color="primary" onClick={onDismiss}>
                            {t('alert_defaultDeclineAction_title')}
                        </Button>
                        <Button color="primary" onClick={onConfirm}>
                            {t('common_yesButton_text')}
                        </Button>
                    </>
                )}

                {variant === CONFIRMATION_VARIANT.INFO && (
                    <Button color="primary" onClick={onConfirm}>
                        {t('auth_common_confirm')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

const Memoized = memo(ConfirmationDialog);
const Styled = styled(Memoized)``;
export default Styled;
