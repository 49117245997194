import { Languages } from 'components/Global/I18N/domain';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Fetch from 'i18next-fetch-backend';
import { initReactI18next } from 'react-i18next';

import { APP_VERSION } from '@/utils/constants/common';

export const setupI18nAsync = async (): Promise<typeof i18n> => {
    i18n.use(Fetch).use(LanguageDetector).use(initReactI18next);

    await i18n.init({
        backend: {
            loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/translations.json?v=${APP_VERSION}`,
        },
        cleanCode: true,
        fallbackLng: Languages.EN,
        supportedLngs: [Languages.EN, Languages.PL, Languages.DE],
        nonExplicitSupportedLngs: true,
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: true,
        },
    });

    return i18n;
};
