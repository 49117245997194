import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import Modal, { Action, IModalProps } from '@/components/common/Modal/Modal';

export enum ModalSize {
    WIDE,
}

export interface IModalBuilderProps extends IModalProps {
    size: ModalSize;
    primaryAction?: Action;
    secondaryAction?: Action;
    disableBackdrop?: boolean;
}

const wrapWithSize = (size: ModalSize, children: React.ReactNode) => {
    switch (size) {
        case ModalSize.WIDE:
            return <WideWrapper>{children}</WideWrapper>;
    }
};

export class ModalBuilder {
    private props: IModalBuilderProps;

    constructor() {
        this.props = {
            open: true,
            size: ModalSize.WIDE,
            children: <></>,
            hideHeader: false,
            heroTitle: false,
        };
    }

    public withTitle(title: string): ModalBuilder {
        this.props.title = title;
        return this;
    }

    public withHeroTitle(title: string): ModalBuilder {
        this.props.title = title;
        this.props.heroTitle = true;
        return this;
    }

    public withHeroImage(hero: JSX.Element): ModalBuilder {
        this.props.hero = hero;
        return this;
    }

    public withoutCloseIcon(): ModalBuilder {
        this.props.hideCloseIcon = true;
        return this;
    }

    public withContent(component: JSX.Element | string): ModalBuilder {
        this.props.children = component;
        return this;
    }

    public withSize(size: ModalSize): ModalBuilder {
        this.props.size = size;
        return this;
    }

    public withOnClose(onClose?: () => void): ModalBuilder {
        this.props.onClose = onClose;
        return this;
    }

    public withPrimaryAction(action: Action) {
        this.props.primaryAction = action;
        return this;
    }

    public withSecondaryAction(action: Action) {
        this.props.secondaryAction = action;
        return this;
    }

    public withDisabledBackdrop(disabledBackdrop: boolean) {
        this.props.disableBackdrop = disabledBackdrop;
        return this;
    }

    build(): JSX.Element {
        return <ModalWrapper {...this.props} />;
    }
}

const ModalWrapper = (props: IModalBuilderProps) => {
    const [open, setOpen] = useState(props.open);
    const onClose = useCallback(() => {
        props.onClose?.();
        if (props.disableBackdrop) return;
        setOpen(false);
    }, [setOpen]);

    const [secondaryAction] = useState<Action | undefined>(
        props.secondaryAction
            ? props.secondaryAction.onClick
                ? props.secondaryAction
                : { ...props.secondaryAction, onClick: () => setOpen(false) }
            : undefined
    );

    const content = wrapWithSize(props.size, props.children);
    return (
        <Modal data-testid="modal" {...props} secondaryAction={secondaryAction} open={open} onClose={onClose}>
            {content}
        </Modal>
    );
};

const WideWrapper = styled.div`
    width: 35rem;
    display: flex;
    flex-direction: column;
`;

export const createModal = () => new ModalBuilder();
