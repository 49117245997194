export enum HTTP_METHOD {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH',
}

export enum CONTENT_TYPE {
    FORM = 'application/x-www-form-urlencoded',
    OCTET_STREAM = 'application/octet-stream',
    FORM_DATA = 'multipart/form-data',
    JSON = 'application/json',
    XML = 'application/xml',
    IMAGE = 'image/jpeg',
    TEXT = 'text/plain',
    IMAGE_PNG = 'image/png',
}
