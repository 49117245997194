import { createContext, ReactNode, useCallback, useState } from 'react';

import { ModalBuilder } from './ModalBuilder';

interface ModalOptions {
    open: (modal: ModalBuilder) => void;
    close: () => void;
}

export const ModalContext = createContext<ModalOptions>({
    open: () => undefined,
    close: () => undefined,
});

export const ModalProvider = ({ children }: { children: ReactNode }) => {
    const [modal, setModal] = useState<JSX.Element>();

    const open = useCallback((modal: ModalBuilder) => setModal(modal.build()), [modal]);
    const close = useCallback(() => setModal(undefined), []);

    return (
        <ModalContext.Provider value={{ open, close }}>
            {children}
            {modal}
        </ModalContext.Provider>
    );
};
