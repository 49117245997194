import { createTheme } from '@mui/material/styles';
import * as React from 'react';

import { COLOR, FONT_WEIGHT, PX_TO_REM } from '@/globalStyles';

declare module '@mui/material/Alert' {
    interface AlertPropsVariantOverrides {
        fqInfo: true;
        fqSuccess: true;
        fqWarning: true;
        fqError: true;
    }
}
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        fqRed: true;
        fqBlue: true;
        fqGreen: true;
        fqYellow: true;
        fqBlack: true;
    }
}
declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        fqRed: true;
        fqBlue: true;
        fqGreen: true;
        fqYellow: true;
        fqBlack: true;
    }
}
declare module '@mui/material/ToggleButton' {
    interface ToggleButtonPropsColorOverrides {
        fqRed: true;
        fqBlue: true;
        fqGreen: true;
        fqYellow: true;
        fqBlack: true;
    }
}

declare module '@mui/material/ToggleButtonGroup' {
    interface ToggleButtonGroupPropsColorOverrides {
        fqRed: true;
        fqBlue: true;
        fqGreen: true;
        fqYellow: true;
        fqBlack: true;
    }
}

declare module '@mui/material/Switch' {
    interface SwitchPropsColorOverrides {
        fqRed: true;
        fqBlue: true;
        fqGreen: true;
        fqYellow: true;
        fqBlack: true;
    }
}

declare module '@mui/material/Pagination' {
    interface PaginationPropsColorOverrides {
        fqRed: true;
        fqBlue: true;
        fqGreen: true;
        fqYellow: true;
        fqBlack: true;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        little_text: true;
        tabs: true;
        menu_categories: true;
        main_title: true;
        tags: true;
        tags_autocomplete: true;
        created_by: true;
        table_text: true;
        support_text: true;
        tagggsss: true;
        approver_name: true;
        modal_or_card_title: true;
        input_text: true;
        link: true;
        reg_title: true;
        mid_title: true;
        normal_title: true;
        selected_tab: true;
        button: true;
        regular_text: true;
        tip_text: true;
        tab_bar_title: true;
        breadcrumb: true;
        profile_title: true;
        profile_sub_title: true;
        form_title: true;
        form_label: true;
        box_title: true;
    }
}

declare module '@mui/material/styles' {
    interface TypographyVariants {
        tabs: React.CSSProperties;
        menu_categories: React.CSSProperties;
        main_title: React.CSSProperties;
        little_text: React.CSSProperties;
        tags: React.CSSProperties;
        tagstags_autocomplete: React.CSSProperties;
        created_by: React.CSSProperties;
        table_text: React.CSSProperties;
        support_text: React.CSSProperties;
        tagggsss: React.CSSProperties;
        approver_name: React.CSSProperties;
        modal_or_card_title: React.CSSProperties;
        input_text: React.CSSProperties;
        link: React.CSSProperties;
        reg_title: React.CSSProperties;
        mid_title: React.CSSProperties;
        normal_title: React.CSSProperties;
        selected_tab: React.CSSProperties;
        button: React.CSSProperties;
        regular_text: React.CSSProperties;
        tip_text: React.CSSProperties;
        tab_bar_title: React.CSSProperties;
        breadcrumb: React.CSSProperties;
        profile_title: React.CSSProperties;
        profile_sub_title: React.CSSProperties;
        form_title: React.CSSProperties;
        form_label: React.CSSProperties;
        box_title: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        tabs?: React.CSSProperties;
        menu_categories?: React.CSSProperties;
        little_text?: React.CSSProperties;
        main_title?: React.CSSProperties;
        tags?: React.CSSProperties;
        tags_autocomplete?: React.CSSProperties;
        created_by?: React.CSSProperties;
        table_text?: React.CSSProperties;
        support_text?: React.CSSProperties;
        tagggsss?: React.CSSProperties;
        approver_name?: React.CSSProperties;
        modal_or_card_title?: React.CSSProperties;
        input_text?: React.CSSProperties;
        link?: React.CSSProperties;
        reg_title?: React.CSSProperties;
        mid_title?: React.CSSProperties;
        normal_title?: React.CSSProperties;
        selected_tab?: React.CSSProperties;
        button?: React.CSSProperties;
        regular_text?: React.CSSProperties;
        tip_text?: React.CSSProperties;
        tab_bar_title?: React.CSSProperties;
        breadcrumb?: React.CSSProperties;
        profile_title?: React.CSSProperties;
        profile_sub_title?: React.CSSProperties;
        form_title?: React.CSSProperties;
        form_label?: React.CSSProperties;
        box_title?: React.CSSProperties;
    }
}
declare module '@mui/material/styles' {
    interface Palette {
        fqRed: Palette['primary'];
        fqBlue: Palette['primary'];
        fqGreen: Palette['primary'];
        fqYellow: Palette['primary'];
        fqBlack: Palette['primary'];
    }

    interface PaletteOptions {
        fqRed?: PaletteOptions['primary'];
        fqBlue?: PaletteOptions['primary'];
        fqGreen?: PaletteOptions['primary'];
        fqYellow?: PaletteOptions['primary'];
        fqBlack?: PaletteOptions['primary'];
    }
}

export const materialUITheme = createTheme({
    palette: {
        error: {
            main: COLOR.redWildWatermelon,
            contrastText: COLOR.white,
        },
        fqRed: {
            main: COLOR.red,
            contrastText: COLOR.white,
        },
        fqBlue: {
            main: COLOR.blue,
            contrastText: COLOR.white,
        },
        fqGreen: {
            main: COLOR.mountainMeadow,
            contrastText: COLOR.white,
        },
        fqYellow: {
            main: COLOR.yellow,
            contrastText: COLOR.black,
        },
        fqBlack: {
            main: COLOR.black,
            contrastText: COLOR.white,
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                fontFamily: 'Roboto',
            },
        },
        MuiIcon: {
            defaultProps: {
                baseClassName: 'material-icons-outlined',
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: '1rem',
                },
            },
        },
    },

    typography: {
        fontFamily: 'Roboto',
        tabs: {
            fontSize: PX_TO_REM[17],
            lineHeight: PX_TO_REM[24],
            letterSpacing: '0.2px',
        },
        little_text: {
            fontSize: PX_TO_REM[12],
            lineHeight: PX_TO_REM[16],
            letterSpacing: '0.2px',
        },
        menu_categories: {
            fontSize: PX_TO_REM[14],
            lineHeight: PX_TO_REM[21],
            letterSpacing: '0.1px',
            fontWeight: FONT_WEIGHT.bold,
        },
        main_title: {
            fontSize: PX_TO_REM[30],
            lineHeight: PX_TO_REM[38],
            letterSpacing: '0.15px',
            fontWeight: FONT_WEIGHT.medium,
        },
        tags: {
            fontSize: PX_TO_REM[12],
            lineHeight: PX_TO_REM[21],
            letterSpacing: '0.2px',
            fontWeight: FONT_WEIGHT.medium,
        },
        tags_autocomplete: {
            fontSize: PX_TO_REM[13],
            lineHeight: PX_TO_REM[21],
            letterSpacing: '0.2px',
        },
        created_by: {
            fontSize: PX_TO_REM[12],
            lineHeight: PX_TO_REM[16],
            letterSpacing: '0.2px',
        },
        table_text: {
            fontSize: PX_TO_REM[14],
            lineHeight: PX_TO_REM[17],
            letterSpacing: '0.1px',
        },
        support_text: {
            fontSize: PX_TO_REM[14],
            lineHeight: PX_TO_REM[22],
            letterSpacing: '0.3px',
        },
        tagggsss: {
            fontSize: PX_TO_REM[11],
            lineHeight: PX_TO_REM[21],
            letterSpacing: '0.2px',
        },
        approver_name: {
            fontSize: PX_TO_REM[16],
            lineHeight: PX_TO_REM[17],
            letterSpacing: '0.5px',
            fontWeight: FONT_WEIGHT.medium,
        },
        modal_or_card_title: {
            fontSize: PX_TO_REM[20],
            lineHeight: PX_TO_REM[24],
            letterSpacing: '0.2px',
            fontWeight: FONT_WEIGHT.medium,
        },
        input_text: {
            fontSize: PX_TO_REM[16],
            lineHeight: PX_TO_REM[17],
            letterSpacing: '0.1px',
        },
        link: {
            fontSize: PX_TO_REM[14],
            lineHeight: PX_TO_REM[17],
            letterSpacing: '0.1px',
            textDecorationLine: 'underline',
        },
        reg_title: {
            fontSize: PX_TO_REM[20],
            lineHeight: PX_TO_REM[24],
            letterSpacing: '0.1px',
            fontWeight: FONT_WEIGHT.medium,
        },
        mid_title: {
            fontSize: PX_TO_REM[24],
            lineHeight: PX_TO_REM[30],
            letterSpacing: '0.7px',
            fontWeight: FONT_WEIGHT.medium,
        },
        normal_title: {
            fontSize: PX_TO_REM[24],
            lineHeight: PX_TO_REM[38],
            letterSpacing: '0.15px',
            fontWeight: FONT_WEIGHT.normal,
        },
        selected_tab: {
            fontSize: PX_TO_REM[16],
            lineHeight: PX_TO_REM[16],
            letterSpacing: '0.2px',
            fontWeight: FONT_WEIGHT.medium,
        },
        button: {
            fontSize: PX_TO_REM[14],
            lineHeight: PX_TO_REM[22],
            letterSpacing: '0.1px',
            fontWeight: FONT_WEIGHT.normal,
            textTransform: 'none',
        },
        regular_text: {
            fontSize: PX_TO_REM[16],
            lineHeight: PX_TO_REM[21],
            letterSpacing: '0.02rem',
        },
        tip_text: {
            fontSize: PX_TO_REM[14],
            lineHeight: PX_TO_REM[24],
            letterSpacing: '0.02rem',
        },
        body2: {
            fontSize: PX_TO_REM[14],
            lineHeight: PX_TO_REM[20],
            letterSpacing: '0.25px',
        },
        tab_bar_title: {
            fontSize: PX_TO_REM[14],
            fontWeight: FONT_WEIGHT.medium,
            lineHeight: PX_TO_REM[24],
            letterSpacing: '0.02rem',
        },
        breadcrumb: {
            fontSize: PX_TO_REM[12],
            fontWeight: FONT_WEIGHT.normal,
            lineHeight: 'PX_TO_REM[17]',
            letterSpacing: PX_TO_REM[0.1],
        },
        profile_title: {
            fontSize: PX_TO_REM[14],
            fontWeight: FONT_WEIGHT.normal,
            lineHeight: PX_TO_REM[21],
            letterSpacing: '0.1px',
        },
        profile_sub_title: {
            fontSize: PX_TO_REM[10],
            fontWeight: FONT_WEIGHT.normal,
            fontStyle: 'italic',
            color: COLOR.suvaGrey,
            lineHeight: PX_TO_REM[18],
            letterSpacing: '0.1px',
        },
        form_title: {
            fontSize: PX_TO_REM[15],
            lineHeight: PX_TO_REM[22],
            letterSpacing: PX_TO_REM[0.1],
            fontWeight: FONT_WEIGHT.normal,
        },
        form_label: {
            fontSize: PX_TO_REM[13],
            lineHeight: PX_TO_REM[21],
            letterSpacing: PX_TO_REM[0.2],
            fontWeight: FONT_WEIGHT.normal,
        },
        box_title: {
            fontSize: PX_TO_REM[16],
            lineHeight: PX_TO_REM[22],
            letterSpacing: PX_TO_REM[0.7],
            fontWeight: FONT_WEIGHT.medium,
        },
    },
});
