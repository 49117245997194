import * as OAuth2Manager from '@/dataExchange/OAuth2Manager';
import { APP_VERSION } from '@/utils/constants/common';
import { CONTENT_TYPE, HTTP_METHOD } from '@/utils/enums/dataExchange';

interface FetchDto {
    method?: string;
    headers?: {
        'Content-Type'?: string;
        'X-Authorization'?: string;
        'X-Application'?: string;
        'X-Application-version'?: string;
    };
    body?: string | FormData;
}

type GetFetchOptionsProps = {
    addAuth?: boolean;
    method?: HTTP_METHOD;
    additionalHeaders?: Record<string, string>;
} & (
    | {
          contentType?: CONTENT_TYPE.JSON | null;
          body?: unknown;
      }
    | {
          contentType?: Exclude<CONTENT_TYPE, 'Content-Type'>;
          body?: never;
      }
);

export const getFetchOptions = ({
    addAuth,
    method,
    additionalHeaders,
    contentType = CONTENT_TYPE.JSON,
    body,
}: GetFetchOptionsProps): FetchDto | undefined => {
    const bodyPropertyData = body
        ? {
              body: JSON.stringify(body),
          }
        : {};
    const contentTypeData = contentType ? { 'Content-Type': contentType } : {};
    const authorization = addAuth ? { 'X-Authorization': `Bearer ${OAuth2Manager.getToken()}` } : {};
    const additionalHeadersData = additionalHeaders ?? {};
    const requiredHeaders = { 'X-Application': 'web', 'X-Application-version': APP_VERSION };
    const headersData =
        contentType || addAuth || additionalHeaders
            ? {
                  headers: {
                      ...contentTypeData,
                      ...authorization,
                      ...additionalHeadersData,
                      ...requiredHeaders,
                  },
              }
            : {
                  headers: {
                      ...requiredHeaders,
                  },
              };
    const methodData = method ? { method } : {};

    const methodAndHeaders = { ...methodData, ...headersData };
    const fetchData =
        body instanceof FormData ? { ...methodAndHeaders, body } : { ...methodAndHeaders, ...bodyPropertyData };

    return Object.keys(fetchData).length > 0 ? fetchData : undefined;
};

export const getFetchOptionsWithAuth = (props: GetFetchOptionsProps) => {
    return getFetchOptions({ ...props, addAuth: true });
};
