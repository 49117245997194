import { FQ_NODE_ENV } from '@/utils/constants/common';

export const initializeGTM = () => {
    if (FQ_NODE_ENV !== 'production') return;

    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const f = d.getElementsByTagName(s)[0],
            j: HTMLScriptElement = d.createElement(s as 'script'),
            dl = l != 'dataLayer' ? `&l=${l}` : '';
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
        f?.parentNode?.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-5KGCC67G');

    const noScriptTag = document.createElement('noscript');
    const iframeTag = document.createElement('iframe');
    iframeTag.title = 'Google Tag Manager';
    iframeTag.src = 'https://www.googletagmanager.com/ns.html?id=GTM-5KGCC67G';
    iframeTag.width = '0';
    iframeTag.height = '0';
    iframeTag.setAttribute('style', 'display:none;visibility:hidden');
    noScriptTag.appendChild(iframeTag);
    document.body.insertBefore(noScriptTag, document.body.firstChild);
};
