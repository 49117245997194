import React from 'react';

import { COLOR, COLOR_FUNCTION } from '@/globalStyles';

export const REACT_FATAL_ERROR_CSS = `background: ${COLOR_FUNCTION.errorSecondary}; color: black; font-size: 16px;`;

export class ErrorWrapper extends React.PureComponent<
    Record<string, unknown>,
    {
        hasError: boolean;
    }
> {
    public state = {
        hasError: false,
    };

    static getDerivedStateFromError(error: unknown) {
        console.trace(`%c [React Fatal Error] \n ------------------- \n ${error}`, REACT_FATAL_ERROR_CSS);
        return { hasError: true };
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        return (
            <>
                {hasError && (
                    <div
                        style={{
                            backgroundColor: COLOR.grayBlue,
                            width: '100vw',
                            height: '100vh',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: COLOR.white,
                                padding: '1rem',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate3d(-50%, -50%, 0)',
                                borderRadius: '4px',
                                boxShadow: '0px 0px 4px 1px #909bb01f',
                                fontFamily: 'Open Sans, sans-serif',
                                lineHeight: 1.2,
                            }}
                        >
                            <p
                                style={{
                                    marginBottom: '1rem',
                                }}
                            >
                                Oops, something went very wrong.
                            </p>
                            <p>Try refreshing this page or feel free to contact us if the problem persists.</p>
                        </div>
                    </div>
                )}
                {!hasError && children}
            </>
        );
    }
}

export default ErrorWrapper;
