import React, { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import useAuthController from '@/pages/Auth/useAuthController';

import StripeStatus from './StripeStatus';
import { VariantStatus } from './type';

interface IAuthProps {
    className?: string;
}

export enum STRIPE_STATUS_ROUTES {
    SUCCESS = '/success',
    ERROR = '/error',
}

export type AuthController = ReturnType<typeof useAuthController> | null;
export const AuthContext = React.createContext<AuthController>(null);

const StripePaymentResult = ({ className }: IAuthProps) => {
    return (
        <CenterContainer className={className}>
            <Routes>
                <Route path={STRIPE_STATUS_ROUTES.SUCCESS} element={<StripeStatus variant={VariantStatus.success} />} />
                <Route path={STRIPE_STATUS_ROUTES.ERROR} element={<StripeStatus variant={VariantStatus.error} />} />
            </Routes>
        </CenterContainer>
    );
};
const CenterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
`;
const Memoized = memo(StripePaymentResult);
const Styled = styled(Memoized)`
    & .MuiGrid-root {
        min-height: 100vh;
    }
`;
export default Styled;
