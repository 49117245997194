import { eachDayOfInterval, endOfDay, format, isDate, isValid, isWeekend, parse, parseISO, startOfDay } from 'date-fns';

import { RequestResponseDto } from '@/domain/newRequest';

export enum DATE_FORMAT {
    DAY_MONTH_YEAR = 'dd-MM-yyyy',
    DAY_PURE = 'd',
    DAY_FULL = 'dd',
    DAY_NAME_PURE = 'EEEEE',
    DAY_PURE_AND_MONTH_NAME_FULL = 'd MMMM',
    DAY_FULL_AND_MONTH_NAME_FULL = 'dd MMMM',
    MONTH_NUMERIC_PURE = 'M',
    MONTH_NUMERIC_FULL = 'MM',
    MONTH_NAME_SHORT = 'MMM',
    MONTH_NAME_FULL = 'MMMM',
    MONTH_NAME_FULL_WITH_YEAR_NUMERIC = 'LLLL yyyy',
    YEAR_NUMERIC_FULL = 'yyyy',
    YEAR_AND_MONTH = 'yyyy-MM',
    CALENDAR = 'dd/MM/yyyy',
    FULL_WITH_SPACES = 'dd MMMM yyyy',
    FULL_WITH_DOTS = 'dd.MM.yyyy',
    HOUR_PURE = 'H',
    HOUR_AM_PM = 'hh:mm a',
    DATE_AND_HOURS = 'yyyy-MM-dd hh:mm:ss',
}

export const getDateWithTimezoneOffset = (date: Date) => {
    return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
};

export const parseIsoDate = (date: string) => {
    return getDateWithTimezoneOffset(parseISO(date));
};

export const formatStringDatesToPeriod = (
    from: string,
    to: string,
    dateFormat: DATE_FORMAT = DATE_FORMAT.FULL_WITH_DOTS
) => {
    return `${format(parseIsoDate(from as string), dateFormat)} - ${format(parseIsoDate(to as string), dateFormat)}`;
};

export const formatDatesToPeriod = (from: Date, to: Date, dateFormat: DATE_FORMAT = DATE_FORMAT.FULL_WITH_DOTS) => {
    return `${format(from, dateFormat)} - ${format(to, dateFormat)}`;
};

export const normalizeDateForUI = (date: Date) => {
    if (!isValid(date)) return null;

    return format(date, DATE_FORMAT.FULL_WITH_DOTS);
};

export const normalizeDateForAPI = (date: Date) => {
    return format(date, DATE_FORMAT.DAY_MONTH_YEAR);
};

export const getDateFromNormalized = (date: string) => {
    const [day, month, year] = date?.split('.');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};

export const formatPeriodToWorkDaysNumber = (start: Date, end: Date) => {
    if (!isDate(start) || !isDate(end)) return null;
    if (!isValid(start) || !isValid(end)) return null;

    const eachDay = eachDayOfInterval({ start, end });
    const workDays = eachDay?.filter(day => !isWeekend(day));

    return workDays?.length;
};

export const formatAbsenceISODatesToDates = (absences: RequestResponseDto[]) => {
    return absences?.map(absence => ({
        ...absence,
        from: startOfDay(parseIsoDate(absence?.from)),
        to: endOfDay(parseIsoDate(absence?.to)),
    }));
};
