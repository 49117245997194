import { memo, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import useLoggedInUserData from '@/hooks/useUserData/useLoggedInUserData';
import { AUTH_ROUTES } from '@/pages/Auth/Auth';
import { STRIPE_STATUS_ROUTES } from '@/pages/Auth/StripePaymentResult/StripePaymentResult';
import { ROUTES } from '@/pages/Routing/Routing';

type IProtectedRouteProps = {
    children: JSX.Element;
};

const StripePaymentStatusRoute = ({ children }: IProtectedRouteProps) => {
    const { isAdmin } = useLoggedInUserData();
    const [target, setTarget] = useState<JSX.Element | null>(<></>);
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const success = queryParams.get('success');

    useEffect(() => {
        if (!isAdmin && location.pathname === '/administrator-panel/subscriptions') {
            if (success === 'true') {
                setTarget(
                    <Navigate to={`${ROUTES.AUTH}${AUTH_ROUTES.STRIPE_STATUS}${STRIPE_STATUS_ROUTES.SUCCESS}`} />
                );
            } else if (success === 'false') {
                setTarget(<Navigate to={`${ROUTES.AUTH}${AUTH_ROUTES.STRIPE_STATUS}${STRIPE_STATUS_ROUTES.ERROR}`} />);
            } else {
                setTarget(null);
            }
        } else {
            setTarget(null);
        }
    }, [isAdmin, success, location]);
    return target ?? children;
};

const Memoized = memo(StripePaymentStatusRoute);
const Styled = styled(Memoized)``;
export default Styled;
